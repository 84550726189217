
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

// Helper function to decode JWT and check if it's expired
const decodeJwt = (token) => {
  try {
    const decoded = JSON.parse(atob(token.split('.')[1])); // Decode JWT
    return decoded.exp * 1000 > Date.now(); 
  } catch (e) {
    console.error("Error decoding JWT", e);  
    return false; 
  }
};

// Set up default Axios base URL
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token && decodeJwt(token)) {
      config.headers['Authorization'] = `Bearer ${token}`; 
    } else {
      localStorage.removeItem('token'); 
      delete config.headers['Authorization']; 
    }
    return config;
  },
  (error) => {
    return Promise.reject(error); 
  }
);


axios.interceptors.response.use(
  (response) => response, 
  (error) => {
    if (error.response && error.response.status === 401) {
      //console.log('Unauthorized - Redirecting to login');
      localStorage.removeItem('token'); 
      window.location.href = '/plug'; 
    }
    return Promise.reject(error); 
  }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE}>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);


reportWebVitals();
