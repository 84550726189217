import React, { createContext, useContext, useState } from 'react';

const TripPlannerContext = createContext();

export const useTripPlanner = () => {
  return useContext(TripPlannerContext);
};

export const TripPlannerProvider = ({ children }) => {
  const [isTripPlannerOpen, setIsTripPlannerOpen] = useState(false);

  const toggleTripPlanner = () => {
    setIsTripPlannerOpen((prev) => !prev); // Toggle the visibility
  };

  return (
    <TripPlannerContext.Provider
      value={{
        isTripPlannerOpen,
        toggleTripPlanner,
        setIsTripPlannerOpen, // Make sure you provide setIsTripPlannerOpen
      }}
    >
      {children}
    </TripPlannerContext.Provider>
  );
};
