// src/Components/VerificationPopup.jsx
import React, { useState } from 'react';
import './VerificationPopup.css';

const VerificationPopup = ({ isOpen, onClose, email }) => {
  const [verificationCode, setVerificationCode] = useState('');

  if (!isOpen) return null;

  const handleVerify = (e) => {
    e.preventDefault();
    // Handle verification logic here
    //console.log('Verification Code:', verificationCode);
  };

  const handleResendCode = () => {
    // Handle resend code logic here
    //console.log('Resending verification code...');
  };

  return (
    <div className="ver-popup-overlay">
      <div className="ver-popup-content">
        <button className="ver-close-btn" onClick={onClose}>X</button>
        <h2 className='ver-h2'>Confirm your Email</h2>
        <hr className='ver-hr' />

        <div className="ver-message-box">
          Account created. Please enter the confirmation code sent to your email to verify your account.
        </div>

        <form onSubmit={handleVerify}>
          <label className= 'ver-label' htmlFor="ver-email">Email *</label>
          <input
            type="email"
            id="email"
            value={email}
            disabled
            className="ver-input-email"
          />

          <label className= 'ver-label' htmlFor="verificationCode">Verification Code</label>
          <input
            type="text"
            id="verificationCode"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            className="ver-input-code"
            required
          />

          <button type="submit" className="ver-verify-btn">VERIFY</button>
        </form>

        <div className="ver-footer">
          Didn't get the email? <span onClick={handleResendCode} className="resend-link">Resend Verification Code</span>
        </div>
      </div>
    </div>
  );
};

export default VerificationPopup;
