import React, { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { useUser } from '../../Context/UserContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AddPublicStation.css';


const mapContainerStyle = {
    width: '100%',
    height: '300px',
};



const center = {
    lat: 6.9271, // Default latitude (e.g., Colombo, Sri Lanka)
    lng: 79.8612, // Default longitude
};


const AddHomeStation = ({ isOpen, closeHome }) => {
    const [markerPosition, setMarkerPosition] = useState(center);
    const [address, setAddress] = useState('');
    const [locationName, setLocationName] = useState('');
    const [desc, setDesc] = useState('');
    const [hours, setHours] = useState('');
    const [price, setPrice] = useState('');
    const [phone, setPhone] = useState('');
    const [parkingLevel, setParkingLevel] = useState('');
    const [clearence, setClearence] = useState('');
    const [openStatus, setOpenStatus] = useState();
    const [connectors, setConnectors] = useState([]);
    const [selectedConnector, setSelectedConnector] = useState('');
    const [selectedConnectors, setSelectedConnectors] = useState({}); // Store selected connectors for each station

    const [amenities, setAmenities] = useState([]);
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [parkingAttributes, setParkingAttributes] = useState([]);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [restrictions, setRestrictions] = useState([]);
    const [selectedRestrictions, setSelectedRestrictions] = useState([]);
    const [isRestrictedAccessChecked, setIsRestrictedAccessChecked] = useState(false);
    const [paymentRequired, setPaymentRequired] = useState(false);


    const [fullDay, setFullDay] = useState(false);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDzVoII7EFE7OJZ35jPFcd0SMLe0DHMcT8', // Replace with your Google Maps API Key
    });
    const { userId } = useUser();
    const [stations, setStations] = useState([
        {
            id: 1,
            plugType: "",
            networkType: "",
        },
    ]);

    useEffect(() => {
        const fetchConnectors = async () => {
            try {
                // Replace with your backend URL
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_CONNECTOR_GET_ALL}`
                );
                if (response.data.success) {
                    setConnectors(response.data.data); // Store the connectors data in the state
                } else {
                    console.error('Failed to fetch connectors');
                }
            } catch (error) {
                console.error('Error fetching connectors:', error);
            }
        };

        fetchConnectors();
    }, []);

    useEffect(() => {
        const fetchParkingAttributes = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_P_ATTRIBUTES_GET_ALL}`
                );
                if (response.data.success) {
                    setParkingAttributes(response.data.data); // Set fetched data into state
                }
            } catch (error) {
                console.error('Error fetching parking attributes:', error);
            }
        };

        fetchParkingAttributes();
    }, []);

    useEffect(() => {
        const fetchRestrictions = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_RESTRICTIONS_GET_ALL}`
                );
                if (response.data.success) {
                    setRestrictions(response.data.data); // Set fetched data into state
                }
            } catch (error) {
                console.error('Error fetching access restrictions:', error);
            }
        };

        fetchRestrictions();
    }, []);


    useEffect(() => {
        const fetchAmenities = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_AMENTITIES_GET_ALL}`
                );
                if (response.data.success) {
                    setAmenities(response.data.data); // Set fetched data into state
                }
            } catch (error) {
                console.error('Error fetching amenities:', error);
            }
        };

        fetchAmenities();
    }, []);

    const handleDuplicate = (id) => {
        const newStation = {
            id: Date.now(), // Unique ID for the new station
            plugType: "",
            networkType: "",
        };
        setStations((prevStations) => {
            const index = prevStations.findIndex((station) => station.id === id);
            const newStations = [...prevStations];
            newStations.splice(index + 1, 0, newStation); // Insert after the duplicated station
            return newStations;
        });
    };

    const handleRemove = (id) => {
        setStations((prevStations) => prevStations.filter((station) => station.id !== id));
    };

    const geocodePosition = (lat, lng) => {
        const geocoder = new window.google.maps.Geocoder();
        const latLng = new window.google.maps.LatLng(lat, lng);
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === 'OK' && results[0]) {
                setAddress(results[0].formatted_address); // Set the address in the input
            } else {
                alert('Geocode failed: ' + status);
            }
        });
    };

    const geocodeAddress = (address) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: address }, (results, status) => {
            if (status === 'OK' && results[0]) {
                const lat = results[0].geometry.location.lat();
                const lng = results[0].geometry.location.lng();
                setMarkerPosition({ lat, lng });
            } else {
                alert('Geocode failed: ' + status);
            }
        });
    };

    const handleMapClick = useCallback((event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setMarkerPosition({ lat, lng });
        geocodePosition(lat, lng);
    }, []);

    const handleMovePinToAddress = () => {
        if (address) {
            geocodeAddress(address);
        } else {
            alert('Please enter an address first');
        }
    };

    /*  const handleConnectorChange = (e) => {
       setSelectedConnector(e.target.value);
   
     }; */

    const handleConnectorChange = (e, stationId) => {
        setSelectedConnectors((prevSelected) => {
            const updatedSelected = { ...prevSelected, [stationId]: e.target.value }; // Update selected connector for the specific station
            return updatedSelected;
        });
    };


    const handleCheckboxChange = (id) => {
        setSelectedAmenities((prevSelected) => {
            const updatedSelected = prevSelected.includes(id)
                ? prevSelected.filter((itemId) => itemId !== id)
                : [...prevSelected, id];

            return updatedSelected;
        });
    };

    const handleParkingCheckboxChange = (id) => {
        setSelectedAttributes((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((itemId) => itemId !== id);
            } else {
                return [...prevSelected, id];
            }
        });
    };

    const handleRestrictionCheckboxChange = (id) => {
        setSelectedRestrictions((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter((itemId) => itemId !== id);
            } else {
                return [...prevSelected, id];
            }
        });
    };

    useEffect(() => {
        //console.log('Selected P Attributes:', selectedAttributes); // Logs the updated selected amenities
    }, [selectedAttributes]);

    useEffect(() => {
        //console.log('Selected controller:', selectedRestrictions); // Logs the updated selected amenities
    }, [selectedRestrictions]);

    useEffect(() => {
        //console.log('Selected Amenities:', selectedAmenities); // Logs the updated selected amenities
        //console.log(userId)
    }, [selectedAmenities]);

    useEffect(() => {
        //console.log('Selected controller:', selectedConnector); // Logs the updated selected amenities
    }, [selectedConnector]);

    const handleRestrictedAccessChange = (e) => {
        setIsRestrictedAccessChecked(e.target.checked);
    };


    /*  const handleSubmit = async (e) => {
       e.preventDefault();
   
       const stationData = {
         name: locationName,
         address: address,
         desc: desc,
         restrictedAccessIds: Array.isArray(selectedRestrictions) ? selectedRestrictions : [selectedRestrictions],
         //selectedPlugIds: Array.isArray(selectedConnector) ? selectedConnector : [selectedConnector],
         selectedPlugIds: Object.values(selectedConnectors),
         amentityIds: Array.isArray(selectedAmenities) ? selectedAmenities : [selectedAmenities],
         parkingAttributeIds: Array.isArray(selectedAttributes) ? selectedAttributes : [selectedAttributes],
         fullDay: fullDay ? 1 : 0,
         paymentRequired: paymentRequired ? 1 : 0,
         hours: hours,
         price: price,
         phone: Number(phone.replace(/\D/g, '')),
         parkingLevel: parkingLevel,
         clearence: clearence,
         openStatus: openStatus ? 1 : 0,
         location: {
           name: locationName,
           address: address,
           lat: markerPosition.lat,
           longField: markerPosition.lng,
           city: address,
           district: address,
           description: desc,
           userId: userId,
   
         },
       };
   
       console.log(JSON.stringify(stationData, null, 2));  // Pretty print the object
   
       try {
         const response = await axios.post(
           `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ADD_STATION}`,
           stationData
         );
         if (response.data.success) {
           toast.success('🚀 Station added successfully!', {
             position: 'top-center',
             autoClose: 3000, // 3 seconds
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             theme: 'light',
           });
           closePublic(); // Close the modal after successful submission
         } else {
           toast.error('Failed to add station. Please try again.', {
             position: 'top-center',
             autoClose: 3000,
           });
         }
       } catch (error) {
         console.error('Error adding station:', error);
         if (error.response) {
           console.log('Error response:', error.response.data);
           console.log('Error status:', error.response.status);
           alert(`Error: ${error.response.data.message || 'An error occurred'}`);
         } else if (error.request) {
           alert('No response from the server');
         } else {
           alert('An error occurred while setting up the request');
         }
       }
     };
    */

    const clearForm = () => {
        setLocationName('');
        setAddress('');
        setDesc('');
        setHours('');
        setPrice('');
        setPhone('');
        setParkingLevel('');
        setClearence('');
        setOpenStatus();
        setSelectedConnectors({});
        setSelectedAmenities([]);
        setSelectedAttributes([]);
        setSelectedRestrictions([]);
        setIsRestrictedAccessChecked(false);
        setPaymentRequired(false);
        setFullDay(false);
        setMessage('');
        setMessageType('');
        setMarkerPosition(center);  // Reset the marker position to the default center
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const stationData = {
            name: locationName,
            address: address,
            desc: desc,
            restrictedAccessIds: Array.isArray(selectedRestrictions) ? selectedRestrictions : [selectedRestrictions],
            selectedPlugIds: Object.values(selectedConnectors),
            amentityIds: Array.isArray(selectedAmenities) ? selectedAmenities : [selectedAmenities],
            parkingAttributeIds: Array.isArray(selectedAttributes) ? selectedAttributes : [selectedAttributes],
            fullDay: fullDay ? 1 : 0,
            paymentRequired: paymentRequired ? 1 : 0,
            hours: hours,
            price: price,
            phone: Number(phone.replace(/\D/g, '')),
            parkingLevel: parkingLevel,
            clearence: clearence,
            openStatus: openStatus ? 1 : 0,
            location: {
                name: locationName,
                address: address,
                lat: markerPosition.lat,
                longField: markerPosition.lng,
                city: address,
                district: address,
                description: desc,
                userId: userId,
            },
        };

        //console.log(JSON.stringify(stationData, null, 2));  // Pretty print the object

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ADD_STATION}`,
                stationData
            );
            if (response.data.success) {
                setMessage('🚀 Station added successfully!');
                setMessageType('success');
            } else {
                setMessage('Failed to add station. Please try again.');
                setMessageType('error');
            }
        } catch (error) {
            console.error('Error adding station:', error);

            if (error.response) {
                setMessage(`Error: ${error.response.data.message || 'An error occurred while processing the request'}`);
                setMessageType('error');
            } else if (error.request) {
                setMessage('No response from the server. Please check your network connection and try again.');
                setMessageType('error');
            } else {
                setMessage('An error occurred while setting up the request. Please try again.');
                setMessageType('error');
            }
        }
    };

    if (!isOpen || !isLoaded) return null;

    return (
        <div className="station-modal-overlay" onClick={closeHome}>
            <div className="station-modal-window" onClick={(e) => e.stopPropagation()}>
                {/* Header Section */}
                <div className="station-modal-header">
                    <h2 className="station-h2-title">Add Location</h2>
                    <button className="station-cls-btn" onClick={closeHome}>
                        &times;
                    </button>
                </div>

                <div className="station-modal-content">
                    {/* Location Form */}
                    <div className="station-form-section">
                        <div className="station-input-group">
                            <input type="text" placeholder="Location Name *" required value={locationName}  // Bind the locationName state
                                onChange={(e) => setLocationName(e.target.value)} />
                            <input
                                type="text"
                                placeholder="Full Street Address *"
                                required
                                value={address} // Bind the address state to this input field
                                onChange={(e) => setAddress(e.target.value)} // Optional: Make the address input field read-only
                            />
                        </div>

                        {/* Map Section */}
                        <div className="station-map-container">
                            <div className="station-map-buttons">
                                <button className="station-map-btn" onClick={handleMovePinToAddress}>Move Pin to Address</button>
                                <button className="station-map-btn">Set Address to Pin</button>
                            </div>

                            {/* Google Map Integration */}
                            <div className="station-map-placeholder">
                                <GoogleMap
                                    mapContainerStyle={mapContainerStyle}
                                    zoom={16}
                                    center={markerPosition}
                                    onClick={handleMapClick}
                                >
                                    <Marker position={markerPosition} />
                                </GoogleMap>
                            </div>
                        </div>

                        {/* Description Field */}
                        <div className="station-input-group">
                            <textarea placeholder="Description" value={desc} // Bind the description state
                                onChange={(e) => setDesc(e.target.value)}></textarea>
                        </div>

                        <h3>Stations</h3>
                        <div className="station-section">
                            {stations.map((station) => (
                                <div className="station-dropdown" key={station.id}>
                                    <div className="station-dropdown-header">
                                        <button
                                            className="station-copy-btn"
                                            title="Duplicate"
                                            onClick={() => handleDuplicate(station.id)}
                                        >
                                            &#x2398;
                                        </button>
                                        <button
                                            className="station-close-btn"
                                            title="Remove"
                                            onClick={() => handleRemove(station.id)}
                                        >
                                            &times;
                                        </button>
                                    </div>
                                    <select
                                        /* value={selectedConnector}
                                          onChange={handleConnectorChange} */
                                        value={selectedConnectors[station.id] || ''}
                                        onChange={(e) => handleConnectorChange(e, station.id)}
                                    >
                                        <option>Select Plug *</option>
                                        {connectors.map((connector) => (
                                            <option key={connector.id} value={connector.id}>
                                                {connector.connectorType}
                                            </option>
                                        ))}
                                    </select>
                                    <select>
                                        <option>Non-networked</option>
                                        <option>Networked</option>
                                        <option>Adani</option>
                                        <option>Tesla Supercharger</option>
                                        <option>ChargePoint</option>
                                        <option>EVgo</option>
                                        <option>Blink</option>
                                        <option>Electrify America</option>
                                        <option>Greenlots</option>
                                        <option>SemaConnect</option>
                                        <option>Shell Recharge</option>
                                        <option>EVBox</option>
                                        <option>Other</option>
                                    </select>

                                </div>
                            ))}
                            <button
                                className="add-station-btn"
                                onClick={() =>
                                    setStations((prevStations) => [
                                        ...prevStations,
                                        { id: Date.now(), plugType: "", networkType: "" },
                                    ])
                                }
                            >
                                Add Another Station
                            </button>
                        </div>

                        <div className="station-toggles">
                            <div>
                                <label>Open 24/7</label>
                                <input type="checkbox" checked={fullDay}
                                    onChange={(e) => setFullDay(e.target.checked ? 1 : 0)} />
                            </div>

                        </div>

                        <div className="station-input-group">
                            <input type="text" placeholder="Hours" value={hours}
                                onChange={(e) => setHours(e.target.value)} />

                        </div>

                        <div className="pub-station-buttons">
                            <button className="station-cancel-btn" onClick={clearForm}>Cancel</button>
                            <button className="station-submit-btn" onClick={handleSubmit} >Submit</button>
                        </div>

                        {message && (
                            <div
                                style={{
                                    marginTop: '20px',
                                    padding: '10px',
                                    backgroundColor: messageType === 'success' ? '#4caf50' : '#f44336',
                                    color: 'white',
                                    borderRadius: '5px',
                                    textAlign: 'center',
                                }}
                            >
                                {message}
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div >
    );
};

export default AddHomeStation;
