// SliderComponent.jsx
import React, { useState } from 'react';
import ReactSlider from 'react-slider';
import './SliderComponent.css'; // Import your CSS styles here

const SliderComponent = () => {
    const [value, setValue] = useState([0, 350]);

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    return (
        <div className="slider-container">
            
            <div className="slider">
                <ReactSlider
                    value={value}
                    onChange={handleChange}
                    min={0}
                    max={350}
                    renderTrack={(props, state) => (
                        <div {...props} className="slider-track" />
                    )}
                    renderThumb={(props, state) => (
                        <div {...props} className="slider-thumb" />
                    )}
                />
            </div>
            <div className="current-range">
                {value[0]} kW - {value[1]} kW
            </div>

            <div className="scale-labels">
      <span className="scale-start">0 kW</span>
      <span className="scale-end">350+ kW</span>
    </div>
        </div>
    );
};

export default SliderComponent;
