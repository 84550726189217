import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../../Context/UserContext.jsx';
import './VehicleManager.css';

const VehicleManagement = ({ isOpen, onClose }) => {
  const { setUserId, userId } = useUser(); 
  const [manufacturers, setManufacturers] = useState([]); 
  const [selectedManufacturer, setSelectedManufacturer] = useState(""); 
  const [selectedModel, setSelectedModel] = useState(""); 
  const [selectedTrim, setSelectedTrim] = useState(""); 
  const [connectors, setConnectors] = useState([]);
  const [message, setMessage] = useState(""); 
  const [messageType, setMessageType] = useState("");

  // To store models and trims based on selected manufacturer and model
  const [availableModels, setAvailableModels] = useState([]);
  const [availableTrims, setAvailableTrims] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_GET_VEHICLE_MAKE_DATA}`
        );
        //(response.data); 
        if (response.data && Array.isArray(response.data)) {
          setManufacturers(response.data); 
        } else {
          setManufacturers([]); 
        }
      } catch (error) {
        console.error("Error fetching vehicle make data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedManufacturer) {
      const manufacturer = manufacturers.find(
        (man) => man.manufacturerName === selectedManufacturer
      );
      if (manufacturer && manufacturer.vehicleModels) {
        setAvailableModels(manufacturer.vehicleModels); 
        setConnectors([]);
        setSelectedModel(""); 
        setSelectedTrim(""); 
        setAvailableTrims([]);
      }
    }
  }, [selectedManufacturer, manufacturers]);

  useEffect(() => {
    if (selectedModel) {
      const manufacturer = manufacturers.find(
        (man) => man.manufacturerName === selectedManufacturer
      );
      const model = manufacturer?.vehicleModels?.find(
        (model) => model.id === parseInt(selectedModel)
      );
  
      if (model) {
        //console.log('Selected Model Connector:', model.connector);
        setConnectors(model.connector ? [model.connector] : []);
        if (model.vehicleTrims) {
          setAvailableTrims(model.vehicleTrims); 
        }
      }
    }
  }, [selectedModel, selectedManufacturer, manufacturers]);

  useEffect(() => {
    //console.log('Id:', userId); // Logs the updated selected amenities
  }, [userId]);

  const handleSave = async (e) => {
    e.preventDefault();
  
    if (!selectedManufacturer) {
      setMessage("Please select a manufacturer.");
      setMessageType("error");
      return;
    }

    if (!userId) {
      setMessage("User is not found!");
      setMessageType("error");
      return;
    }
  
    const selectedMake = manufacturers.find(
      (man) => man.manufacturerName === selectedManufacturer
    );
  
    if (!selectedMake) {
      setMessage("Invalid manufacturer selected. Please try again.");
      setMessageType("error");
      return;
    }
    const vehicleName = selectedModel
      ? `${selectedMake.manufacturerName} ${
          availableModels.find((model) => model.id === parseInt(selectedModel))
            ?.vehicleModelName
        }`
      : selectedMake.manufacturerName;
  
    const payload = {
      vehicleName, 
      vehicleMakeId: selectedMake.id, 
      vehicleModelId: selectedModel ? parseInt(selectedModel) : null, 
      trimIds: selectedTrim ? [parseInt(selectedTrim)] : [], 
      userId: userId ,
    };

    const token = localStorage.getItem('token');
  
    try {
       const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ADD_VEHICLE}`,
        payload
      ); 

      

     

      setMessage("Vehicle saved successfully!");
      setMessageType("success");
      setTimeout(() => {
        setMessage(""); 
        onClose(); 
      }, 2000);

    } catch (error) {
      console.error("Error saving vehicle data:", error);
      setMessage("Error saving vehicle. Please try again.");
      setMessageType("error");
    }
  };
  

  if (!isOpen) return null;

  return (
    <div className="vehicle-management-overlay" onClick={onClose}>
      <div
        className="vehicle-management-popup"
        onClick={(e) => e.stopPropagation()}
      >
        <button className="vehicle-close-btn" onClick={onClose}>
          X
        </button>
        <div className="vehicle-management-content">
          {/* Left side with image */}
          <div className="vehicle-image-section">
          <div className="vehicle-title">
              {/* Conditionally render text based on selection */}
              {!selectedModel ? (
                <>
                  <span className="vehicle-select-text">Select Your</span>
                  <span className="vehicle-vehicle-text">Vehicle</span>
                </>
              ) : (
                <>
                  <span className="vehicle-select-text">
                    {selectedManufacturer}
                  </span>
                  <span className="vehicle-vehicle-text">
                    {availableModels.find((model) => model.id === parseInt(selectedModel))?.vehicleModelName}
                  </span>
                </>
              )}
            </div>
            
            <img
              src="https://assets.plugshare.com/assets/spotlight.jpg"
              alt="Vehicle Spotlight"
              className="vehicle-spotlight-image"
            />
            {connectors.length > 0 && (
              <div className="vehicle-title-new">
                <span className="vehicle-select-text-new">Connector Type: {connectors[0].connectorType}</span>
                
              </div>
            )}
          </div>

          {/* Right side with form */}
          <div className="vehicle-form-section">
            <h3 className="vehicle-form-title">My Vehicles</h3>
            <hr className='vehicle-hr'></hr>
           {/*  <div className="vehicle-header">
              <button className="vehicle-btn">Jaguar I-PACE</button>
              <button className="vehicle-btn-new">New</button>
            </div> */}
            <form className="vehicle-form">
              <div className="vehicle-form-group">
                <label htmlFor="vehicleMake">Make *</label>
                <select
                  id="vehicleMake"
                  className="vehicle-select"
                  value={selectedManufacturer}
                  onChange={(e) => setSelectedManufacturer(e.target.value)}
                >
                  <option value="" disabled>Select Make</option>
                  {manufacturers.map((manufacturer) => (
                    <option key={manufacturer.id} value={manufacturer.manufacturerName}>
                      {manufacturer.manufacturerName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="vehicle-form-group">
                <label htmlFor="vehicleModel">Model</label>
                <select
                  id="vehicleModel"
                  className="vehicle-select"
                  value={selectedModel}
                  onChange={(e) => setSelectedModel(e.target.value)}
                >
                  <option value="" disabled>Select Model</option>
                  {availableModels.map((model) => (
                    <option key={model.id} value={model.id}>
                      {model.vehicleModelName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="vehicle-form-group">
                <label htmlFor="vehicleTrim">Trim</label>
                <select
                  id="vehicleTrim"
                  className="vehicle-select"
                  value={selectedTrim}
                  onChange={(e) => setSelectedTrim(e.target.value)}
                >
                  <option value="" disabled>Select Trim</option>
                  {availableTrims.map((trim) => (
                    <option key={trim.id} value={trim.id}>
                      {trim.trimName}
                    </option>
                  ))}
                </select>
              </div>
              <br></br>
              <br></br>
              <button type="button" className="vehicle-submit-btn" onClick={handleSave}>
                ADD VEHICLE
              </button>
              {message && (
            <div
              className={`vehicle-message ${messageType === "success" ? "success" : "error"}`}
            >
              {message}
            </div>
          )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleManagement;
