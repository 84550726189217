import logo from './logo.svg';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import NavigationBar from './Components/Navigation/NavigationBar.jsx';
import { LanguageProvider } from './Context/LanguageContext.jsx';
import MapComponent from './Components/Map/MapComponent.jsx';
import Footer from './Components/Footer/Footer.jsx';
import Sidebar from './Components/SideBar/Sidebar.jsx';
import { LocationProvider } from './Context/LocationProvider.jsx';
import { TripPlannerProvider } from './Context/TripPlannerContext.jsx';
import TripPlanner from './Components/TripPlanner/TripPlanner.jsx';
import { UserProvider } from './Context/UserContext.jsx';
import {GoogleOAuthProvider} from '@react-oauth/google';



function App() {
  return (
    <LanguageProvider>
      <LocationProvider> 
      <TripPlannerProvider>
      <GoogleOAuthProvider clientId="1042315012961-mtqfgv0oce16r9fbjs8gn54393rjs1pj.apps.googleusercontent.com">
        <UserProvider>
       
          <div className="App">
            <NavigationBar />
            <MapComponent />
           
            <Sidebar />
            <TripPlanner/> 
            <Footer />
          </div>
        
        </UserProvider>
        </GoogleOAuthProvider>
        </TripPlannerProvider>
      </LocationProvider>
    </LanguageProvider>
  );
}

export default App;
