// src/Components/LoginPopup.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TranslationObj from '../../Data/TranslationObj.jsx';
import { useLanguage } from '../../Context/LanguageContext.jsx';
import { GoogleLogin } from '@react-oauth/google';
import { useUser } from '../../Context/UserContext.jsx';
import './Login.css';

const LOGIN_URL = process.env.REACT_APP_API_LOGIN;

const LoginPopup = ({ isOpen, onClose, openRegPopup, handleEmailUpdateLogin }) => {
  const { language } = useLanguage();
  const { setUserId, userId, setVehicles } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //('Id:', userId); // Logs the updated selected amenities
  }, [userId]);

  if (!TranslationObj[language]) {
    console.error(`Language "${language}" is not defined in TranslationObj.`);
    return null;
  }

  const translated = TranslationObj[language];


  if (!isOpen) return null;

  const isValidEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  const isValidPassword = (password) => password.length >= 6;

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrMsg('');
    setSuccessMsg('');

    if (!isValidEmail(email)) {
      setErrMsg('Invalid email address.');
      setIsLoading(false);
      return;
    }

    if (!isValidPassword(password)) {
      setErrMsg('Password must be at least 6 characters long.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/login/authenticate`,
        { username: email, password: password }
      );

      const data = response.data;
      if (data.success) {
        setUserId(data.data.userId);
        localStorage.setItem('userId', data.data.userId);
        sessionStorage.setItem('userName', data.data.username);
        localStorage.setItem('userEmail', data.data.username);
        sessionStorage.setItem('token', data.data.token || '');
        sessionStorage.setItem('refresh-Token', data.data.refreshToken || '');
        setSuccessMsg('Successfully Logged In');
        handleEmailUpdateLogin(email);
        setTimeout(() => {
          onClose();
          handleClose();
        }, 2000);
      } else {
        setErrMsg(data.message || 'Login failed');
      }
    } catch (error) {
      setErrMsg(error.response?.data?.message || 'No Server Response!');
    } finally {
      setIsLoading(false);
    }
  };

  /* const handleGoogleLogin = async (response) => {
    try {
        const googleToken = response.credential;
        if (!googleToken) {
            setErrMsg('Google login failed. No token received.');
            return;
        }

        const result = await axios.post(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_GOOGLE_LOGIN}`,
            { token: googleToken }
        );

      

        const data = result.data;

        if (data.success) {
          const token = data.data.token; // Extract token from the API response
          localStorage.setItem('token', token);
          setUserId(data.data.userId);
          localStorage.setItem('userId', data.data.userId); 
            sessionStorage.setItem('userName', data.data.username);
            localStorage.setItem('userName', data.data.username);
            sessionStorage.setItem('token', data.data.token || '');
            sessionStorage.setItem('refreshToken', data.data.refreshToken || '');

            setSuccessMsg('Successfully Logged in with Google');
            handleEmailUpdateLogin(data.data.username);
            setErrMsg('');

            // Close popup after a delay
            setTimeout(() => {
                onClose();
                setSuccessMsg('');
            }, 2000);  // You can change this timeout duration if needed
        } else {
            setSuccessMsg('');
            setErrMsg(data.message || 'Google login failed');
        }
    } catch (err) {
        console.error('Google login error:', err);
        setErrMsg('Google login failed. Please try again later.');
    }
};

 */

  const handleGoogleLogin = async (response) => {
    try {
      // Check if the Google login response contains the expected token
      const googleToken = response.credential;
      if (!googleToken) {
        setErrMsg('Google login failed. No token received.');
        return;
      }

      //const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI || "https://oauth.pstmn.io/v1/callback";
      //console.log("Google Login URL: ", redirectUri);

      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_GOOGLE_LOGIN}`,
        { token: googleToken,
          //redirectUri: redirectUri
         }
      );

      
      const data = result.data;

      if (data.success) {
        
        const { token, userId, username, refreshToken } = data.data;

        if (token) {
          
          localStorage.setItem('token', token);
          localStorage.setItem('userId', userId);
          localStorage.setItem('userName', username);
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('refreshToken', refreshToken || '');
          sessionStorage.setItem('userName', username);

          setUserId(userId);
          setSuccessMsg('Successfully logged in with Google');
          setErrMsg('');

          // Handle email update (if necessary)
          handleEmailUpdateLogin(username);
          setTimeout(() => {
            onClose();
            setSuccessMsg('');
          }, 2000);
        } else {
          setErrMsg('Google login failed. Token is missing.');
        }
      } else {
        setErrMsg(data.message || 'Google login failed. Please try again.');
      }
    } catch (err) {
      console.error('Google login error:', err);
      setErrMsg('Google login failed. Please try again later.');
    }
  };


  const handleClose = () => {
    setEmail('');
    setPassword('');
    setErrMsg('');
    setSuccessMsg('');
    onClose();  // Ensure the parent component handles closing the popup
  };

  return (
    <div className="popup-overlay-login">
      <div className="popup-content-login">


        <div className="popup-inner-container">
          <div className="left-container-login">
            <h2>{translated.I1 || 'Have you tried the PlugShare Trip Planner?'}</h2>
            <p>{translated.p1 || 'Plan your next trip with our easy to use tool:'}</p>
            <ul>
              <li>
                <span>{translated.I2 || '✓ Focus your search:'}</span><br />
                <span className="sub-text">{translated.d1 || 'Only show locations along your route'}</span>
              </li>
              <li>
                <span>{translated.I3 || '✓ Simple yet flexible:'}</span><br />
                <span className="sub-text">{translated.d2 || 'Filter to be nearby hotels, restaurants, etc.'}</span>
              </li>
              <li>
                <span>{translated.I4 || '✓ Elevation Profiles:'}</span><br />
                <span className="sub-text">{translated.d3 || 'Helps you plan accordingly'}</span>
              </li>
              <li>
                <span>{translated.I5 || '✓ Save and load trips:'}</span><br />
                <span className="sub-text">{translated.d4 || 'Works across devices - its mobile friendly!'}</span>
              </li>
            </ul>

            <p>{translated.p3 || 'Login or Register Now for Instant Access'}</p>
          </div>

          <div className="right-container-login">
            <button className="close-btn-login" onClick={handleClose}>X</button>
            <h2>{translated.loginpg || 'Login'}</h2>

            <form className='form-login' onSubmit={handleLogin}>
              <input
                className='input-login'
                type="text"
                placeholder={translated.un || 'Username'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                className='input-login'
                type="password"
                placeholder={translated.pw || 'Password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button className='button-login' type="submit" disabled={isLoading}>
                {isLoading ? 'Logging in...' : translated.btnlogin || 'Login'}
              </button>

              {errMsg && <p className="error-message">{errMsg}</p>}
              {successMsg && <p className="success-message">{successMsg}</p>}

              <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={() => setErrMsg('Google login failed')}
                render={(renderProps) => (
                  <button
                    className="google-log-btn"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <div className="google-ic-container">
                      <img
                        src="https://assets.plugshare.com/assets/google-icon.png"
                        alt="Google Logo"
                        className="google-lg"
                      />
                    </div>
                    {translated.google_log || 'Continue with Google'}
                  </button>
                )}
              />

              <div className="login-footer">
                <span>{translated.lg || 'New to PlugShare?'} <a href="#" onClick={openRegPopup}>{translated.reg || 'Register'}</a></span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
