import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
// Create a context for the user
const UserContext = createContext();

// Custom hook to use the user context
export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userId, setUserId] = useState(null);
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    //console.log('User ID from localStorage:', storedUserId);
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);
  
  useEffect(() => {
    if (userId) {
      const fetchVehicles = async () => {
        try {
          setLoading(true);
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_GET_VEHICLES}${userId}`
          );
          //console.log('Fetched vehicles:', response.data);
          setVehicles(response.data)
          localStorage.setItem('vehicleDetails', JSON.stringify(response.data.data));

          localStorage.setItem('vehicle', vehicles);
          const updatedVehicles = response.data.data?.map(vehicle => ({
            ...vehicle,
            vehicleFullName: `${vehicle.vehicleMakeName} ${vehicle.vehicleModelName}`,
          }));

          //setVehicles(updatedVehicles || []);
          //console.log('name', vehicles)
        } catch (error) {
          console.error('Error fetching vehicles:', error);
          setVehicles([]);
        } finally {
          setLoading(false);
        }
      };
  
      fetchVehicles();
    }
  }, [userId]);
  

  
  
  
  
  return (
    <UserContext.Provider value={{ userId, setUserId, vehicles, setVehicles, loading  }}>
      {children}
    </UserContext.Provider>
  );
};
