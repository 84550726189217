import React, { createContext, useContext, useState, useEffect } from "react";

const LocationContext = createContext();

export const useLocation = () => useContext(LocationContext);

export const LocationProvider = ({ children }) => {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [startingLocation, setStartingLocation] = useState(null); // New state for StartingLocation
  const [destination, setDestination] = useState(null); // New state for Destination
  const [searchTerm, setSearchTerm] = useState('');
  const [popupContent, setPopupContent] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  // On initial load, check if there's a saved current location in localStorage
  useEffect(() => {
    const savedLocation = localStorage.getItem('userLocation');
    if (savedLocation) {
      setCurrentLocation(JSON.parse(savedLocation));
    }
  }, []);

  // You can implement a mechanism to save the current location to localStorage when it changes
  useEffect(() => {
    if (currentLocation) {
      localStorage.setItem('userLocation', JSON.stringify(currentLocation));
    }
  }, [currentLocation]);

  const openPopup = (station) => {
    setPopupContent(station);  
    setIsPopupOpen(true);      
  };
  
  
  const closePopup = () => {
    setPopupContent(null);
    setIsPopupOpen(false);
  };
  

  return (
    <LocationContext.Provider
      value={{
        currentLocation,
        setCurrentLocation,
        startingLocation, // Include startingLocation in the context value
        setStartingLocation, // Setter for startingLocation
        destination, // Include destination in the context value
        setDestination, // Setter for destination
        searchTerm, // Provide searchTerm in the context
        setSearchTerm, 
        isPopupOpen,
        popupContent,
        openPopup,
        closePopup,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};
