import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LanguageSelection from '../Language/LanguageSelection.jsx';
import LoginPopup from '../Login/Login.jsx';
import RegisterPopup from '../Register/Register.jsx';
import VerificationPopup from '../Register/VerificationPopup.jsx';
import { useLanguage } from '../../Context/LanguageContext.jsx';
import VehicleManagement from '../VehicleManagement/VehicleManagement.jsx';
import { useUser } from '../../Context/UserContext.jsx';
import './NavigationBar.css';
import TranslationObj from '../../Data/TranslationObj.jsx';



const NavigationBar = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { language, setLanguage } = useLanguage();
  const [isLoginPopupOpen, setLoginPopupOpen] = useState(false);
  const [isRegPopupOpen, setRegPopupOpen] = useState(false);
  const [isVerificationOpen, setIsVerificationOpen] = useState(false);
  const [emailForVerification, setEmailForVerification] = useState('');
  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || '');
  const [userName, setUserName] = useState(localStorage.getItem('userName') || '');
  const [user, setUser] = useState(localStorage.getItem('userName') || '');
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isVehicleDropdownOpen, setVehicleDropdownOpen] = useState(false);
  const [isBookmarkDropdownOpen, setBookmarkDropdownOpen] = useState(false);
  const [isManageVehicleOpen, setIsManageVehicleOpen] = useState(false);
  const [vehicleName, setVehicleName] = useState("My Vehicle");

  const { setUserId, vehicles, setVehicles } = useUser();

  /* useEffect(() => {
    console.log('Updated vehicles state in context:', vehicles);
    console.log('Vehi Name:', vehicles.vehicleName);
  }, [vehicles]); */

  /*  useEffect(() => {
     if (vehicles && vehicles.length > 0) {
       setVehicleName(vehicles[0].vehicleModelName || "My Vehicle"); // Set the first vehicle's model name or default to "My Vehicle"
     } else {
       setVehicleName("My Vehicle"); // Default value if no vehicle is found
     }
 
     console.log('Updated vehicles state:', vehicles);
     console.log('Vehicle Name:', vehicles && vehicles.length > 0 ? vehicles[0].vehicleModelName : "My Vehicle");
   }, [vehicles]); */

  useEffect(() => {
    if (vehicles && vehicles.length > 0) {
      setVehicleName(vehicles[0].vehicleModelName || "My Vehicle"); // Set the first vehicle's model name or default to "My Vehicle"
    } else {
      setVehicleName("My Vehicle"); // Default value if no vehicle is found
    }

    //console.log('Updated vehicles state:', vehicles);
    //console.log('Vehicle Name:', vehicles && vehicles.length > 0 ? vehicles[0].vehicleModelName : "My Vehicle");
  }, [vehicles]);

  const handleVehicleSelect = (vehicle) => {
    // Swap the selected vehicle with the first vehicle in the vehicles array
    const updatedVehicles = [vehicle, ...vehicles.filter(v => v.id !== vehicle.id)];
    setVehicles(updatedVehicles);  // Update the vehicles state with the swapped array
    setVehicleName(vehicle.vehicleModelName); // Update vehicle name to the selected vehicle's model name
    setVehicleDropdownOpen(false); // Close the dropdown after selection
  };

  const openPopup = () => setPopupOpen(true);
  const closePopup = (newLanguage) => {
    setPopupOpen(false);
    if (newLanguage) setLanguage(newLanguage);
  };


  const openRegPopup = () => {
    setRegPopupOpen(true);
    setLoginPopupOpen(false);
  };

  const closeRegPopup = () => {
    setRegPopupOpen(false);
  };

  const openLoginPopup = () => {
    setLoginPopupOpen(true);
    setRegPopupOpen(false);
  };

  const closeLoginPopup = () => {
    setLoginPopupOpen(false);
  };


  const closeVerificationPopup = () => setIsVerificationOpen(false);
  const closeVehiclePopup = () => setVehicleDropdownOpen(false);

  const openVerificationPopup = (email) => {
    setEmailForVerification(email);
    setIsVerificationOpen(true);
  };

  useEffect(() => {

    if (userEmail) {
      const name = userEmail.split('@')[0];
      setUserName(name);
      setUser(name);
      localStorage.setItem('userName', name);
    }
  }, [userEmail]);

  const handleEmailUpdate = (email) => {

    setUserEmail(email);
    const emailParts = email.split('@');
    let name = emailParts[0];
    const specialCharsRegex = /[^a-zA-Z0-9.]/;
    const specialCharIndex = name.search(specialCharsRegex);
    if (specialCharIndex !== -1) {
      name = name.slice(0, specialCharIndex);
    }
    setUserName(name);
    localStorage.setItem('userEmail', email);
    localStorage.setItem('userName', name);
  };

  const handleEmailUpdateLogin = (email) => {
    setUserEmail(email);
    const name = email.split('@')[0];
    setUser(name);
    localStorage.setItem('userEmail', email);
    localStorage.setItem('userName', name);
  };

  const handleLogout = () => {
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userName');
    localStorage.removeItem('userId');
    setUserEmail('');
    setUserName('');
    setDropdownOpen(false);
    setUserId(null);
  };



  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);
  const toggleVehicleDropdown = () => setVehicleDropdownOpen(!isVehicleDropdownOpen);


  const toggleBookmarkDropdown = () => setBookmarkDropdownOpen(!isBookmarkDropdownOpen);


  if (!TranslationObj[language]) {
    console.error(`Language "${language}" is not defined in TranslationObj.`);
    return null;
  }

  return (
    <>
      <nav className="navigation-bar">
        <div className="logo">
          <Link to="/">{TranslationObj[language].logo}</Link>
        </div>
        <div className="nav-links">
          <Link to="/business" className="nav-link plug-link">{TranslationObj[language].business}</Link>
          <button className="nav-link-btn" onClick={openPopup}
            style={{ marginRight: userName ? '28px' : '0' }}>
            {language}
          </button>



          {userName ? (
            <>

              <div
                className="username-dropdown"
                onMouseEnter={() => setDropdownOpen(true)}
                onMouseLeave={() => setDropdownOpen(false)}
              >
                <Link to="#" className="nav-link username-link">
                  <div className="profile-icon">
                    <i className="fas fa-user"></i>
                  </div>
                  {userName}'s Profile
                </Link>
                {isDropdownOpen && (
                  <div className="dropdown-menu">
                    <button onClick={handleLogout} className="dropdown-item">Logout</button>
                  </div>
                )}
              </div>
             
              <div
                className="nav-item-vehicle"
                onMouseEnter={() => setVehicleDropdownOpen(true)}
                onMouseLeave={() => setVehicleDropdownOpen(false)}
              >
                <Link to="#" className="nav-link vehicle">
                  {vehicleName}
                </Link>
                {isVehicleDropdownOpen && (
                  <div className="dropdown-menu">
                    {vehicles && vehicles.length > 1 && vehicles.slice(1).map((vehicle) => (
                      <Link
                        key={vehicle.id}
                        to="#"
                        className="dropdown-item"
                        onClick={(e) => {
                          e.preventDefault();
                          handleVehicleSelect(vehicle); 
                        }}
                      >
                        {vehicle.vehicleModelName} 
                      </Link>
                    ))}
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => {
                        setVehicleDropdownOpen(false);
                        setIsManageVehicleOpen(true); 
                      }}
                    >
                      Manage Vehicle
                      <i className="fa fa-list-alt vehicle-icon"></i>
                    </Link>
                  </div>
                )}
              </div>

              {/* Bookmark dropdown */}
              <div className="nav-item-bookmark" onMouseEnter={toggleBookmarkDropdown} onMouseLeave={toggleBookmarkDropdown} >
                <Link to="#" className="nav-link bookmark">Bookmark</Link>
                {isBookmarkDropdownOpen && (
                  <div className="dropdown-menu">
                    <span className="dropdown-item">No bookmarks</span>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <Link to="#" onClick={openLoginPopup} className="nav-link login-link">
                {TranslationObj[language].account}
              </Link>
              <Link to="#" onClick={openRegPopup} className="nav-link register-link">
                {TranslationObj[language].register}
              </Link>
            </>
          )}
        </div>
      </nav>
      <LanguageSelection isOpen={isPopupOpen} onClose={closePopup} />
      <LoginPopup
        isOpen={isLoginPopupOpen}
        onClose={closeLoginPopup}
        openRegPopup={openRegPopup}
        handleEmailUpdateLogin={handleEmailUpdateLogin}
      />
      <RegisterPopup
        isOpen={isRegPopupOpen}
        onClose={closeRegPopup}
        openLoginPopup={openLoginPopup} // Pass the function to open login popup
        openVerificationPopup={openVerificationPopup}
        handleEmailUpdate={handleEmailUpdate}
      />
      <VerificationPopup
        isOpen={isVerificationOpen}
        onClose={closeVerificationPopup}
        email={emailForVerification}
      />
      {isManageVehicleOpen && (
        <VehicleManagement
          isOpen={isManageVehicleOpen}
          onClose={() => setIsManageVehicleOpen(false)} // Close popup when needed
        />
      )}


    </>
  );
};

export default NavigationBar;
