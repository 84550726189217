import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { GoogleMap, LoadScript, Marker, TrafficLayer, StreetViewPanorama, DirectionsRenderer, InfoWindow } from '@react-google-maps/api';
import { useLanguage } from '../../Context/LanguageContext.jsx';
import TranslationObj from '../../Data/TranslationObj.jsx';
import { useLocation } from '../../Context/LocationProvider.jsx';
import StationPopup from './StationPop.jsx';
import './MapComponent.css'
import '@fortawesome/fontawesome-free/css/all.min.css';

const containerStyle = {
  width: '100%',
  height: '100%',
};

const MapComponent = () => {
  const { language } = useLanguage();
  const [trafficLayerVisible, setTrafficLayerVisible] = useState(false);
  const [streetViewVisible, setStreetViewVisible] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [directions, setDirections] = useState(null);
  const [stations, setStations] = useState([]);
  
  const [selectedStation, setSelectedStation] = useState(null);

  const mapRef = useRef(null);
  const streetViewRef = useRef(null);
  const { currentLocation, startingLocation, destination, openPopup, isPopupOpen, popupContent } = useLocation(); // Use Location context for startingLocation and destination

  // Retrieve location from localStorage if it exists
  useEffect(() => {
    const storedLocation = JSON.parse(localStorage.getItem('currentLocation'));
    if (storedLocation) {
      setMarkerPosition(storedLocation);
    }
  }, []);

  

  useEffect(() => {
    const fetchStations = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_GET_ALL_STATIONS}`
        );
        //console.log('Fetched stations:', response.data);

        // Now access the correct data key in the response
        if (Array.isArray(response.data.data)) {
          setStations(response.data.data); // Set the stations array from response.data.data
        } else {
          console.error("Response data is not an array:", response.data.data);
        }
      } catch (error) {
        console.error("Error fetching stations:", error);
      }
    };

    fetchStations();
  }, []);

  const handleMarkerClick = (station) => {
    setSelectedStation(station);
    openPopup(station);
  };

  //console.log('MapComponent:', { currentLocation, startingLocation, destination });

  // Store the location in localStorage whenever currentLocation changes
  useEffect(() => {
    if (currentLocation) {
      localStorage.setItem('currentLocation', JSON.stringify(currentLocation));
      setMarkerPosition(currentLocation); // Set the marker to current location
    }
  }, [currentLocation]);

  useEffect(() => {
    if (startingLocation && destination) {
      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer();

      directionsService.route(
        {
          origin: startingLocation,
          destination: destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === 'OK') {
            setDirections(result);
            directionsRenderer.setDirections(result);
          } else {
            console.error('Error fetching directions:', status);
          }
        }
      );
    }
  }, [startingLocation, destination]);


  // Calculate the route using the DirectionsService
  const handleCalculateRoute = () => {
    const directionsService = new window.google.maps.DirectionsService();

    const request = {
      origin: new window.google.maps.LatLng(startingLocation.lat, startingLocation.lng),
      destination: new window.google.maps.LatLng(destination.lat, destination.lng),
      travelMode: window.google.maps.TravelMode.DRIVING,
    };

    directionsService.route(request, (result, status) => {
      if (status === window.google.maps.DirectionsStatus.OK) {
        setDirections(result);
      } else {
        console.error("Error fetching directions:", status);
      }
    });
  };

  // Recalculate route when startingLocation or destination changes
  useEffect(() => {
    if (startingLocation && destination) {
      handleCalculateRoute();
    }
  }, [startingLocation, destination]);

  // Handle the map load
  const handleMapLoad = (map) => {
    mapRef.current = map;
    if (currentLocation) {
      map.panTo(currentLocation);
      map.setZoom(18);
    }
  };

  // Pan the map when currentLocation changes
  useEffect(() => {
    if (mapRef.current && currentLocation) {
      mapRef.current.panTo(currentLocation);
      mapRef.current.setZoom(18);
      setMarkerPosition(currentLocation); // Ensure marker position is set
    }
  }, [currentLocation]);

  // Toggle traffic layer
  const toggleTrafficLayer = () => {
    setTrafficLayerVisible((prev) => !prev);
  };

  // Toggle street view
  const toggleStreetView = () => {
    setStreetViewVisible((prev) => !prev);
  };

  // Handle street view load
  const handleStreetViewLoad = (panorama) => {
    streetViewRef.current = panorama;
  };

  const closePopup = () => {
    setSelectedStation(null); // Clear selected station
    openPopup(false); // Close popup
  };
  

  useEffect(() => {
    if (!isPopupOpen) {
      setSelectedStation(null); // Close the popup if isPopupOpen is false
    }
  }, [isPopupOpen]);

  if (!TranslationObj[language]) {
    console.error(`Language "${language}" is not defined in TranslationObj.`);
    return null;
  }

  const translated = TranslationObj[language];

  return (
    <LoadScript googleMapsApiKey="AIzaSyDzVoII7EFE7OJZ35jPFcd0SMLe0DHMcT8">
      <div className="map-container">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={startingLocation && destination ? {
            lat: (startingLocation.lat + destination.lat) / 2,
            lng: (startingLocation.lng + destination.lng) / 2
          } : currentLocation || { lat: 7.8731, lng: 80.7718 }}
          zoom={18}
          options={{ disableDefaultUI: false, mapTypeControl: false }}
          onLoad={handleMapLoad}
        >
          {markerPosition && (
            <Marker position={markerPosition || { lat: 7.8731, lng: 80.7718 }} />
          )}

          {stations?.map(station => (
            <Marker
              key={station.id}
              position={{ lat: station.location.lat, lng: station.location.longField }}
              title={station.name}
              onClick={() => handleMarkerClick(station)}
            />
          ))}

          {selectedStation && (
            <InfoWindow
              position={{
                lat: selectedStation.location.lat,
                lng: selectedStation.location.longField,
              }}
              onCloseClick={() => setSelectedStation(null)}
              
            >
              <div style={{
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                padding: '15px',
                minWidth: '250px',
                fontFamily: 'Arial, sans-serif',
                fontSize: '14px',
                color: '#333',
                overflow: 'hidden',

              }}>
                <h3 style={{
                  margin: '0 0 10px',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  color: '#007BFF', // Blue color for the header
                  textAlign: 'center',
                }}


                >
                  {selectedStation.name}
                </h3>
                <div style={{ marginTop: '10px' }}>
                  <p><strong>Address:</strong> {selectedStation.address || 'N/A'}</p>
                  <p>
                    <strong>Connectors:</strong>
                    {selectedStation.selectedPlugs && selectedStation.selectedPlugs.length > 0
                      ? selectedStation.selectedPlugs.map((plug, index) => (
                        <span key={index} style={{
                          display: 'inline-block',
                          backgroundColor: '#e6f7ff',
                          padding: '4px 8px',
                          marginRight: '6px',
                          borderRadius: '15px',
                          fontSize: '12px',
                          color: '#0056b3', // Blue text color
                          marginBottom: '6px',
                        }}>
                          {plug.connectorType}
                        </span>
                      ))
                      : 'N/A'}
                  </p>
                  <p>
                    <strong>Status:</strong>
                    <span style={selectedStation.status === 1
                      ? { color: 'green', fontWeight: 'bold' }
                      : { color: 'red', fontWeight: 'bold' }}>
                      {selectedStation.status === 1 ? 'Available' : 'Unavailable'}
                    </span>
                  </p>
                </div>
              </div>
            </InfoWindow>
          )}

          <Marker position={startingLocation} />
          <Marker position={destination} />

          {directions && <DirectionsRenderer directions={directions} />}

          {/* Traffic Layer */}
          {trafficLayerVisible && <TrafficLayer />}

          {/* Street View Panorama */}
          {streetViewVisible && (
            <StreetViewPanorama
              position={currentLocation || { lat: 7.8731, lng: 80.7718 }}
              visible={streetViewVisible}
              options={{ pov: { heading: 165, pitch: 0 }, zoom: 18 }}
              onLoad={handleStreetViewLoad}
            />
          )}
        </GoogleMap>

        {isPopupOpen && selectedStation && (
          <StationPopup
            isOpen={isPopupOpen}
            station={selectedStation} // Pass the entire station object to the popup
            onClose={closePopup}
          />
        )}

        {/* Traffic Layer Toggle Button */}
        <button
          onClick={toggleTrafficLayer}
          style={{
            position: 'absolute',
            bottom: '60px',
            right: '60px',
            zIndex: 1000,
            background: 'white',
            border: 'none',
            padding: '10px',
            borderRadius: '4px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold'
          }}
        >
          <i className="fa fa-car" style={{ marginRight: '8px', color: trafficLayerVisible ? '#ff5733' : '#a9a9a9' }}></i>
          {translated.traffic || 'Traffic'}
        </button>

        {/* Street View Toggle Button */}
        <button
          onClick={toggleStreetView}
          style={{
            position: 'absolute',
            bottom: '22px',
            right: '60px',
            zIndex: 1000,
            background: 'white',
            border: 'none',
            padding: '10px',
            borderRadius: '4px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold'
          }}
        >
          <i className="fa fa-street-view" style={{ marginRight: '8px', color: streetViewVisible ? '#008cff' : '#a9a9a9' }}></i>
          {translated.streetView || 'Street View'}
        </button>
      </div>
    </LoadScript>
  );
};

export default MapComponent;
