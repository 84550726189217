import React, { useState } from 'react';
import TranslationObj from '../../Data/TranslationObj.jsx';
import { useLanguage } from '../../Context/LanguageContext.jsx';
import './ComingSoon.css';

const ComingSoon = () => {
  const { language } = useLanguage();

  const [includeComingSoon, setIncludeComingSoon] = useState(false);
  const [showOnlyComingSoon, setShowOnlyComingSoon] = useState(true);
  const [hideComingSoon, setHideComingSoon] = useState(false);

  if (!TranslationObj[language]) {
    console.error(`Language "${language}" is not defined in TranslationObj.`);
    return null;
  }

  const translated = TranslationObj[language];

  // Handlers for each checkbox
  const handleIncludeChange = () => setIncludeComingSoon(!includeComingSoon);
  const handleShowOnlyChange = () => setShowOnlyComingSoon(!showOnlyComingSoon);
  const handleHideChange = () => setHideComingSoon(!hideComingSoon);

  return (
    <div className="filter-block3">
      <label className="filter-label1">{translated.rd1 || "Availability"}</label>
      <div className="checkbox-options">
        <label>
          <input 
            type="checkbox" 
            checked={includeComingSoon} 
            onChange={handleIncludeChange} 
          />
          <span className="checkbox-box"></span>{translated.coming || "Coming Soon"}
        </label>
        <label>
          <input 
            type="checkbox" 
            checked={showOnlyComingSoon} 
            onChange={handleShowOnlyChange} 
          />
          <span className="checkbox-box"></span>{translated.rd2 || "Available"}
        </label>
        
      </div>
    </div>
  );
};

export default ComingSoon;
