import React from 'react';
import styles from './AddStation.css';  // Ensure this is the correct path for styles

const AddStation = ({ isOpen, onClose }) => {
  //console.log('open',isOpen);
  if (!isOpen) return null;  // Don't render if the modal is not open

  return (
    <div >
      <div >
        <span className={styles.closeButton} onClick={onClose}>&times;</span>
        <h2>Add Station</h2>
        <p>Fill in the details for the new station here.</p>
        <form>
          <input type="text" placeholder="Station Name" />
          <button type="submit">Add Station</button>
        </form>
      </div>
    </div>
  );
};

export default AddStation;
