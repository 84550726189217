import React from 'react';
import TranslationObj from '../../Data/TranslationObj.jsx';
import { useLanguage } from '../../Context/LanguageContext.jsx';
import './LanguagePopup.css'; 

const LanguageSelection = ({ isOpen, onClose }) => {
  const { language } = useLanguage();

  if (!TranslationObj[language]) {
    console.error(`Language "${language}" is not defined in TranslationObj.`);
    return null; 
  }

  const translated = TranslationObj[language];
 

  if (!isOpen) return null;

  return (
    <div className="popup-overlay-lan">
      <div className="popup-content-lan">
      <button className="close-button-lan" onClick={() => onClose()}>X</button>
        <h2>{translated.lang || 'Select Language'}</h2>
        <button className='button-1' onClick={() => onClose('EN')}>English</button>
        <button className='button-1' onClick={() => onClose('සිං')}>සිංහල</button>
        <button className='button-1' onClick={() => onClose('தமிழ்')}>தமிழ்</button>
        
      </div>
    </div>
  );
};

export default LanguageSelection;
