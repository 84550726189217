// TranslationObj.jsx
const TranslationObj = {
  EN: {
    business: 'PlugShare for Business',
    account: 'Login',
    register: 'Register',
    logo: 'PlugShare',
    searchPlaceholder: 'Search for a Charging Location',
    legend: 'legend',
    filters: 'Filters',
    add_station: 'Add Stations',
    trip: 'Trip Planner',
    recent: 'Recent Activity',
    setting: 'Settings',
    help: 'Help',
    feedback: 'Submit Feedback',
    store: 'PlugShare Store',
    app: 'Get the App',
    taffic: 'Traffic',
    loginpg: 'Login',
    btnlogin: 'Login',
    lg: 'New to PlugShare?',
    un: 'Email',
    pw: 'Password',
    reg: 'Register',
    lang: 'Select Language',
    I1: 'Have you tried the PlugShare Trip Planner?',
    p1: 'Plan your next trip with our easy to use tool:',
    I2: '✓ Focus your search:',
    d1: 'Only show locations along your route',
    I3: '✓ Simple yet flexible:',
    d2: 'Filter to be nearby hotels, restaurants, etc.',
    I4: '✓ Elevation Profiles:',
    d3: 'Helps you plan accordingly',
    I5: '✓ Save and load trips:',
    d4: 'Works across devices - its mobile friendly!',
    p3: 'Login or Register Now for Instant Access',
    regpg: 'Register',
    btnreg: 'Create Account',
    rg: 'Already have an account?',
    plugscore: 'PlugScore',
    msgScore1: 'Locations will not be filtered by PlugScore',
    msgScore2: 'Locations with a PlugScore of {value} or greater will be visible.',
    kWrange: 'Kilowatt Range:',
    stationCount: 'Station Count',
    any: 'Any',
    amentities: 'Amentities',
    dining: 'Dining',
    Rroom: 'Restrooms',
    shoping: 'Shopping',
    lodging: 'Lodging',
    park: 'Park',
    grocery: 'Grocery',
    wifi: 'WiFi',
    vParking: 'Valet Parking',
    hiking: 'Hiking',
    camping: 'Camping',
    Fcharging: 'Free Charging',
    Afilter: 'Additional Filters',
    lab1: 'Hide Dealerships',
    lab2: 'Hide Restricted Access',
    lab3: 'Show Private Homes',
    lab4: 'Available Now',
    lab5: 'Hide Tesla Only Locations',
    coming: 'Coming Soon',
    rd1: 'Availability',
    rd2: 'Available',
    parking: 'Parking ({value} to 4)',
    park1: 'Accessible',
    park2: 'Pull through',
    park3: 'Pull in',
    park4: 'Trailer friendly',
    vehiclep: 'Vehicle & Plugs',
    vehiclep1: 'Your vehicle is used to determine compatible charging stations',
    plugs: 'Plugs ({value} to 13)',
    loc: 'Location',
    loc2: 'Use my current location',
    reset: 'Reset',
    addS1: 'Add Public Location',
    addS2: 'Share Home Charger',
    trip1: 'Plan a New Trip',
    plug_history: 'Pay with PlugShare History',
    history1: 'Get started with pay with PlugShare',
    google_log: 'Continue with Google'




  },
  සිං: { // Sinhala
    business: 'ව්යාපාරික plugshare',
    account: 'පරිශීලක ගිණුම',
    register: 'නව ගිණුම',
    logo: 'PlugShare',
    searchPlaceholder: 'චාජින් ස්ථානයක් සොයන්න',
    legend: 'ලිපිනය',
    filters: 'පෙරහන',
    add_station: 'ස්ථාන එක් කරන්න',
    trip: 'සැලසුම්',
    recent: 'මෑත ක්‍රියාකාරකම්',
    setting: 'සැකසුම්',
    help: 'උදව්',
    feedback: 'ප්‍රතිචාර ඉදිරිපත් කරන්න',
    store: 'PlugShare ගබඩාව',
    app: 'යෙදුම ලබා ගන්න',
    traffic: 'රථ වාහන තදබදය',
    loginpg: 'පිවිසෙන්න',
    btnlogin: 'පිවිසෙන්න',
    lg: 'PlugShare සඳහා අළුත්ද?',
    un: 'විද්‍යුත් තැපෑල',
    pw: 'මුරපදය',
    reg: 'ලියාපදිංචි වන්න',
    lang: 'භාෂාව තෝරන්න',
    I1: 'ඔබ PlugShare ගමන් සැලසුම් කරුවකු ඇත්ද?',
    p1: 'ඔබේ අනාගත ගමන මෙතරම් පහසු මෙවලමක් සමඟ සැලසුම් කරන්න:',
    I2: '✓ ඔබේ සොයා ගැනීම යොමු කරන්න:',
    d1: 'ඔබේ මාර්ගය පරාසයේ ආශ්‍රිත ස්ථාන පමණක් පෙන්වන්න',
    I3: '✓ සරල හා නම්යශීලී පරිදි:',
    d2: 'ආසන්න හෝටල්, ආහාර අවන්හල් ආදීය තෝරන්න.',
    I4: '✓ උන්නතාංශ පැතිකඩ:',
    d3: 'ඔබට අවශ්‍ය පරිදි සැලසුම් කිරීමට උදව් කරනවා',
    I5: '✓ ගමන් සුරකින්න සහ බාගත කරන්න:',
    d4: 'උපාංග මත ක්‍රියා කරයි - ජංගමය හිතකරයි!',
    p3: 'දැන් සක්‍රිය ප්‍රවේශයක් සඳහා ඇතුල් වන්න හෝ ලියාපදිංචි වන්න',
    regpg: 'ලියාපදිංචි වන්න',
    btnreg: 'ගිණුම සාදන්න',
    rg: 'දැනටමත් ගිණුමක් ඇත්ද?',
    plugscore: 'PlugScore',
    msgScore1: 'ප්ලග්ස්කෝර් මගින් ස්ථාන සටහන් නොකරනු ඇත',
    msgScore2: 'ප්ලග්ස්කෝර් {value} ක් හෝ ඊට වැඩි ගණනක් සහිත ස්ථාන දක්නට ලැබෙනු ඇත.',
    kWrange: 'කිලෝවොට් පරාසය:',
    stationCount: 'ස්ථාන ගණන',
    any: 'ඕනෑම',
    amentities: 'සුවපහසුකම්',
    dining: 'අවන්හල්',
    Rroom: 'වැසිකිලි',
    shoping: 'සාප්පු ',
    lodging: 'නේවාසිකාගාර',
    park: 'උද්යාන',
    grocery: 'සිල්ලර බඩු',
    wifi: 'වයිෆයි',
    vParking: 'වැලන්ට් නැවැත්වීම',
    hiking: 'හයිකින්',
    camping: 'කැම්පින්',
    Fcharging: 'නොමිලේ ආරෝපණය',
    Afilter: 'අමතර පෙරහන',
    lab1: 'විකිණීම්පිළිවෙල තැන් සඟවන්න',
    lab2: 'සීමිත ප්‍රවේශය ඇති තැන් සඟවන්න',
    lab3: 'පෞද්ගලික නිවෙස් පෙන්වන්න',
    lab4: 'දැනටමත් ලබා ගත හැක',
    lab5: 'ටෙස්ලාට පමණක් වෙන් වූ ස්ථාන සඟවන්න',
    coming: 'ඉදිරියේ දී',
    rd1: 'ස්ථාපිත බව',
    rd2: 'ලබා ගත හැක',
    parking: 'නවතා තැබීම ({value} න් 4 දක්වා)',
    park1: 'ප්‍රවේශ විය හැකි',
    park2: 'ඉස්සරවීමට පහසු',
    park3: 'ඇතුල් වීම',
    park4: 'ට්‍රේලර් සඳහා පහසු',
    vehiclep: 'වාහන සහ ප්ලග්',
    vehiclep1: 'ඔබගේ වාහනය යොදා ගන්නේ අනුකූල චාජ් කිරීමේ ස්ථාන තීරණය කිරීමටය',
    plugs: 'ප්ලග් ({value} න් 13)',
    loc: 'ස්ථානය',
    loc2: 'මා දැන් සිටින ස්ථානය භාවිතා කරන්න',
    reset: 'නැවත සකසන්න',
    addS1: 'පොදු ස්ථානය එක් කරන්න',
    addS2: 'ගෙදර චාජර් බෙදා හරින්න',
    trip1: 'නව ගමනක් සැලසුම් කරන්න',
    plug_history: 'ප්ලග්ශෙයා සමඟ ගෙවීම් ඉතිහාසය',
    history1: 'ප්ලග්ශෙයා සමඟ ගෙවීම් ආරම්භ කරන්න'


  },
  தமிழ்: { // Tamil
    business: 'வணிக பிளக் ஷேர்',
    account: 'பயனர் கணக்கு',
    register: 'பதிவு செய்',
    logo: 'PlugShare',
    searchPlaceholder: 'சார்ஜிங் இடத்தைத் தேடுங்கள்',
    legend: 'அடையாளம்',
    filters: 'வடிகட்டிகள்',
    add_station: 'நிலையங்களைச் சேர்க்கவும்',
    trip: 'பயண திட்டம்',
    recent: 'சமீபத்திய செயல்பாடு',
    setting: 'அமைப்புகள்',
    help: 'உதவி',
    feedback: 'கருத்துக்களைச் சமர்ப்பிக்கவும்',
    store: 'PlugShare கடை',
    app: 'பயன்பாட்டை பெறுங்கள்',
    traffic: 'போக்குவரத்து நெரிசல்',
    loginpg: 'உள்நுழைய',
    btnlogin: 'உள்நுழைய',
    lg: 'PlugShare இல் புதியவர்?',
    un: 'பயனர் பெயர்',
    pw: 'கடவுச்சொல்',
    reg: 'பதிவு செய்',
    lang: 'மொழியை தேர்வுசெய்க',
    I1: 'நீங்கள் PlugShare பயண திட்டக்கருவியை முயற்சித்தீர்களா?',
    p1: 'உங்கள் அடுத்த பயணத்தை இத்தகைய எளிதான கருவியுடன் திட்டமிடுங்கள்:',
    I2: '✓ உங்கள் தேடலை மையமாக்கவும்:',
    d1: 'உங்கள் பாதையில் உள்ள இடங்களை மட்டுமே காட்டு',
    I3: '✓ எளிமையான மற்றும் நெகிழ்வானது:',
    d2: 'அருகில் உள்ள ஹோட்டல்கள், உணவகங்கள் போன்றவற்றைப் பின்வட்டம் செய்யவும்.',
    I4: '✓ உயர்வு மெய்யியல்:',
    d3: 'நீங்கள் பொருத்தமாக திட்டமிட உதவுகிறது',
    I5: '✓ பயணங்களைச் சேமிக்கவும், ஏற்றவும்:',
    d4: 'செயலிகளைப் பிளவாக கையாளுங்கள் - இது மொபைல் நட்பு!',
    p3: 'இப்பொழுது உடனடி அணுகலுக்காக உள்நுழைக அல்லது பதிவு செய்யவும்',
    regpg: 'பதிவு செய்யவும்',
    btnreg: 'கணக்கு உருவாக்கவும்',
    rg: 'ஏற்கனவே ஒரு கணக்கு உள்ளதா?',
    plugscore: 'பிளக் ஸ்கோர்',
    msgScore1: 'பிளக் ஸ்கோரால் இடங்கள் வடிகட்டப்படமாட்டாது',
    msgScore2: 'பிளக் ஸ்கோர் {value} அல்லது அதற்கு மேற்பட்ட இடங்கள் காணப்படும்.',
    kWrange: 'கிலோவாட் வரம்பு:',
    stationCount: 'நிலையங்களின் எண்ணிக்கை',
    any: 'ஏதேனும்',
    amentities: 'வசதிகள்',
    dining: 'Dining',
    Rroom: 'Restrooms',
    shoping: 'Shopping',
    lodging: 'Lodging',
    park: 'Park',
    grocery: 'Grocery',
    wifi: 'WiFi',
    vParking: 'Valet Parking',
    hiking: 'Hiking',
    camping: 'Camping',
    Fcharging: 'Free Charging',
    Afilter: 'கூடுதல் வடிகட்டி',
    lab1: 'டீலர்ஷிப்களை மறைக்கவும்',
    lab2: 'வரையறுக்கப்பட்ட அணுகலை மறைக்கவும்',
    lab3: 'தனியார் வீடுகளை காட்டவும்',
    lab4: 'தற்போது கிடைக்கிறது',
    lab5: 'டெஸ்லா மட்டும் இடங்களை மறைக்கவும்',
    coming: 'விரைவில் வரும்',
    rd1: 'அமைக்கப்பட்டுள்ளது',
    rd2: 'கிடைக்கும்',
    parking: 'நிறுத்தும் இடம் ({value} இல் 4 வரை)',
    park1: 'அணுகக்கூடியது',
    park2: 'நுழைய எளிதாக',
    park3: 'உள்ளே செல்லவும்',
    park4: 'டிரெய்லர் நட்பாக',
    vehiclep: 'வாகனங்கள் மற்றும் பிளக்ஸ்',
    vehiclep1: 'உங்கள் வாகனம் இணக்கமான சார்ஜிங் நிலையங்களை தீர்மானிக்க பயன்படுத்தப்படுகிறது',
    plugs: 'பிளக்ஸ் ({value} இல் 13 வரை)',
    loc: 'இடம்',
    loc2: 'என் தற்போதைய இருப்பிடத்தைப் பயன்படுத்தவும்',
    reset: 'மீட்டமைக்கவும்',
    addS1: 'பொது இடத்தை சேர்க்கவும்',
    addS2: 'வீட்டு சார்ஜரை பகிரவும்',
    trip1: 'புதிய பயணத்தைத் திட்டமிடுங்கள்',
    plug_history: 'PlugShare உடன் பணம் செலுத்தும் வரலாறு',
    history1: 'PlugShare மூலம் செலுத்தத் தொடங்கவும்',




  },
};

export default TranslationObj;
