import React, { useState, useEffect } from 'react';
import ReactSlider from 'react-slider';
import { useLocation } from '../../Context/LocationProvider.jsx'
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useLanguage } from '../../Context/LanguageContext.jsx';
import TranslationObj from '../../Data/TranslationObj.jsx';
import AddStation from './AddStation.jsx';
import SliderComponent from './SliderComponent';
import MapComponent from '../Map/MapComponent.jsx';
import ComingSoon from './ComingSoon.jsx';
import TripPlanner from '../TripPlanner/TripPlanner.jsx';
import { useTripPlanner  } from '../../Context/TripPlannerContext.jsx';
import AddPublicStation from '../AddStation/AddPublicStation.jsx';
import AddHomeStation from '../AddStation/AddHomeStation.jsx';
import './Sidebar.css';

const Sidebar = () => {
  const { language } = useLanguage();
  const [isOpen, setIsOpen] = useState(false);
  const [isPlugOpen, setIsPlugOpen] = useState(false);
 // const [searchTerm, setSearchTerm] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [tripDropdownOpen, setTripDropdownOpen] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(0);
  const [values, setValues] = useState([0, 350]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [activePlugFilters, setActivePlugFilters] = useState([]);
  const { currentLocation, setCurrentLocation , searchTerm, setSearchTerm } = useLocation();
  const [isAdditionalContainerOpen, setIsAdditionalContainerOpen] = useState(false);
  const [useCurrentLocation, setUseCurrentLocation] = useState(false);
  const [isPublicStationOpen, setIsPublicStationOpen] = useState(false);
  const [isHomeStationOpen, setIsHomeStationOpen] = useState(false);

  const [activeParkingFilters, setActiveParkingFilters] = useState([]);
  const { isTripPlannerOpen, toggleTripPlanner, setIsTripPlannerOpen } = useTripPlanner();

  const filterPlugLabels = [
    'CCS2',
    'CCS1',
    'CHAdeMO',
    'J-1772',
    'Type2',
    'Type3',
    'Three',
    'Caravan',
    'GB/T',
    'GB/T fast',
    'Wall',
    'Wall2',
    'Wall3',
  ];
  let marginLeft;

  if (currentValue === 10) {
    marginLeft = -51;
  } else if (currentValue === 7) {
    marginLeft = -22;
  } else if (currentValue === 6) {
    marginLeft = -11;
  }
  else if (currentValue === 8) {
    marginLeft = -30;
  } else if (currentValue === 9) {
    marginLeft = -41;
  } else if (currentValue === 4) {
    marginLeft = 11;
  } else if (currentValue === 3) {
    marginLeft = 22;
  }
  else {
    marginLeft = Math.max(50 - currentValue * 10, 0);
  }

 // Inside the useEffect for location updates
useEffect(() => {
  const savedRadioState = localStorage.getItem('useCurrentLocation');
  if (savedRadioState !== null) {
    setUseCurrentLocation(JSON.parse(savedRadioState)); // Set it to the stored value if available
  }
}, []);

useEffect(() => {
  if (useCurrentLocation) {
    localStorage.setItem('useCurrentLocation', JSON.stringify(true));
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
          localStorage.setItem('userLocation', JSON.stringify({ lat: latitude, lng: longitude }));
          //console.log('Location fetched:', { lat: latitude, lng: longitude }); // Debug location
        },
        (error) => {
          console.error('Error getting location:', error);
          alert('Unable to retrieve your location.');
        }
      );
    }
  } else {
    localStorage.setItem('useCurrentLocation', JSON.stringify(false));
    setCurrentLocation(null); // Clear the current location when unchecked
    //console.log('Current location cleared'); // Debug location clear
  }
}, [useCurrentLocation, setCurrentLocation]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    //console.log('Searching for:', searchTerm);
  };

  const handleRangeChange = (e) => {
    setCurrentValue(Number(e.target.value));
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openPublic = () => setIsPublicStationOpen(true);
  const closePublic = () => setIsPublicStationOpen(false);

  const openHome = () => setIsHomeStationOpen(true);
  const closeHome = () => setIsHomeStationOpen(false);


  const clearSearch = () => {
    setSearchTerm('');
  };

  const toggleDropdown = (menu) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  const toggleTripDropdown = (menu) => {
    setTripDropdownOpen((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));
  };

  const togglePlugDropdown = () => {
    setIsPlugOpen(!isPlugOpen);
  };

  

  const handleFilterClick = (label) => {
    setActiveFilters((prev) => {
      if (prev.includes(label)) {
        return prev.filter((item) => item !== label);
      } else {
        if (prev.length < 5) {
          return [...prev, label];
        }
        return prev;
      }
    });
  };

  const handleParkingClick = (label) => {
    setActiveParkingFilters((prev) => {
      if (prev.includes(label)) {
        return prev.filter((item) => item !== label);
      } else {
        if (prev.length < 5) {
          return [...prev, label];
        }
        return prev;
      }
    });
  };

  const handlePlugFilterClick = (label) => {
    setActivePlugFilters((prev) => {
      if (prev.includes(label)) {
        return prev.filter((item) => item !== label);
      } else {
        if (prev.length < 13) {
          return [...prev, label];
        }
        return prev;
      }
    });
  };

  const handleLocationChange = (event) => {
    const { checked } = event.target;
    setUseCurrentLocation(checked);  // Toggle the state based on the radio button
    //console.log('Radio button checked:', checked); // Debug radio button change
  };

  const toggleAdditionalContainer = () => {
    setIsAdditionalContainerOpen(!isAdditionalContainerOpen);
    setIsTripPlannerOpen(true);
  };

  const handleReset = () => {


  };

  //console.log(isModalOpen) ;

  const translated = TranslationObj[language];

  const filterLabels = [
    translated.lab1 || 'Hide Dealerships',
    translated.lab2 || 'Hide Restricted Access',
    translated.lab3 || 'Show Private Homes',
    translated.lab4 || 'Available Now',
    translated.lab5 || 'Hide Tesla Only Locations'
  ];

  const parkingLabels = [
    translated.park1 || 'Accessible',
    translated.park2 || 'Pull through',
    translated.park3 || 'Pull in',
    translated.park4 || 'Trailer friendly'];

  return (
    <>
      <div className={`menu-container ${isOpen ? 'open' : 'closed'}`}>
        <div className="menu-icon" onClick={toggleMenu}>
          {isOpen ? 'X' : '☰'} {/* Toggle icon */}
        </div>
        {isOpen && (
          <div className="menu-content">
            <form onSubmit={handleSearchSubmit} className="search-form">
              <input
                type="text"
                placeholder={translated.searchPlaceholder || "Search for a Charging Location"}
                className="search-input"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <button
                type="button"
                className="search-button"
                onClick={searchTerm ? clearSearch : handleSearchSubmit}
              >
                <i className={searchTerm ? "fa fa-times" : "fa fa-search"}></i>
              </button>
            </form>
          </div>
        )}
      </div>
      {isOpen && (
        <div className="additional-container">
          <div className="filter-block3">
            <label className="filter-label1">{translated.loc || 'Location'}</label>
            <div className="location-radio">
              <input
                type="radio"
                id="use-current-location"
                name="location"
                value="currentLocation"
                checked={useCurrentLocation}
                onChange={(e) => handleLocationChange(e)}
              />
              <label htmlFor="use-current-location">{translated.loc2 || 'Use my current location'}</label>
            </div>
          </div>

          <div className="dropdown">
            <button onClick={() => toggleDropdown('legend')} className="dropdown-button">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
              {translated.legend || 'Legend'}
              <span className="dropdown-icon">&#9662;</span>
            </button>
            {dropdownOpen.legend && (
              <div className="dropdown-content">
                <p>Legend content goes here.</p>
              </div>
            )}
          </div>

          <div className="dropdown">
            <button onClick={() => toggleDropdown('filters')}>
              <i className="fa fa-filter" aria-hidden="true"></i>
              {translated.filters || 'Filters'}
              <span className="dropdown-icon">&#9662;</span>
            </button>
            {dropdownOpen.filters && (
              <div className="dropdown-content">
                <div className="filter-blocks">
                  <div className="filter-block">
                    <label className="filter-label1">{translated.plugscore || 'PlugScore'}</label>
                    <div className="range-container">
                      <input
                        type="range"
                        min="0"
                        max="10"
                        step="1"
                        className="filter-range"
                        value={currentValue}
                        onChange={handleRangeChange}
                      />
                      <div className="current-value" style={{
                        left: `${(currentValue / 10) * 100}%`,
                        marginLeft: `${marginLeft}px`,
                        transform: "translateX(-50%)",
                      }}>
                        <div className="md-sign">
                          <span className="md-thumb-text">{currentValue}</span>
                        </div>
                      </div>

                    </div>
                    <div className="plugscore-message">
                      {currentValue === 0 ? (
                        translated.msgScore1 || 'Locations will not be filtered by PlugScore'
                      ) : (
                        translated.msgScore2.replace('{value}', currentValue) || `Locations with a PlugScore of ${currentValue} or greater will be visible.`

                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="filter-block2">
                    <label className="filter-label1">{translated.kWrange || 'Kilowatt Range:'}</label>
                    <div className="range-container2">
                      <SliderComponent />
                    </div>
                  </div>
                  <hr />
                  <div className="filter-block3">
                    <label className="filter-label1">{translated.stationCount || 'Station Count'}</label>
                    <div className="toggle-button-group">
                      <button className="toggle-btn">{translated.any || 'Any'}</button>
                      <button className="toggle-btn">2+</button>
                      <button className="toggle-btn">4+</button>
                      <button className="toggle-btn">6+</button>
                    </div>
                  </div>

                  <hr />
                  <div className="filter-block3">
                    <label className="filter-label1">{translated.amentities || 'Amenities'}</label>
                    <div className="filter-box">
                      <div className="filter-item">
                        <i className="fas fa-utensils dining-icon"></i>
                        <span className="filter-text">{translated.dining || 'Dining'}</span>
                      </div>
                      <div className="filter-item">
                        <i className="fas fa-restroom restroom-icon"></i>
                        <span className="filter-text">{translated.Rroom || 'Restrooms'}</span>
                      </div>
                      <div className="filter-item">
                        <i className="fas fa-shopping-bag shopping-icon"></i>
                        <span className="filter-text">{translated.shoping || 'Shopping'}</span>
                      </div>
                      <div className="filter-item">
                        <i className="fas fa-bed lodging-icon"></i>
                        <span className="filter-text">{translated.lodging || 'Lodging'}</span>
                      </div>
                      <div className="filter-item">
                        <i className="fas fa-tree park-icon"></i>
                        <span className="filter-text">{translated.park || 'Park'}</span>
                      </div>
                      <div className="filter-item">
                        <i className="fas fa-shopping-cart grocery-icon"></i>
                        <span className="filter-text">{translated.grocery || 'Grocery'}</span>
                      </div>
                      <div className="filter-item">
                        <i className="fas fa-wifi wifi-icon"></i>
                        <span className="filter-text">{translated.wifi || 'WiFi'}</span>
                      </div>
                      <div className="filter-item">
                        <i className="fas fa-parking valet-icon"></i>
                        <span className="filter-text">{translated.vParking || 'Valet Parking'}</span>
                      </div>
                      <div className="filter-item">
                        <i className="fas fa-hiking hiking-icon"></i>
                        <span className="filter-text">{translated.hiking || 'Hiking'}</span>
                      </div>
                      <div className="filter-item">
                        <i className="fas fa-campground camping-icon"></i>
                        <span className="filter-text">{translated.camping || 'Camping'}</span>
                      </div>
                      <div className="filter-item">
                        <i className="fas fa-charging-station charging-icon"></i>
                        <span className="filter-text">{translated.Fcharging || 'Free Charging'}</span>
                      </div>
                      <div className="filter-item"></div>
                    </div>
                  </div>
                  <hr />
                  <div className="filter-block3">
                    <label className="filter-label1">
                      {translated.Afilter || 'Additional Filters'} ({activeFilters.length} to 5)
                    </label>
                    <div className="filter-chips">
                      {filterLabels.map((label, index) => (
                        <div
                          key={index}
                          className={`filter-chip ${activeFilters.includes(label) ? 'active' : ''}`}
                          onClick={() => handleFilterClick(label)}
                        >
                          {label}
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr />
                  <div className="filter-block3">
                    <ComingSoon />
                  </div>
                  <hr />
                  <div className="filter-block3">
                    <label className="filter-label1">
                      {translated.parking
                        ? translated.parking.replace('{value}', activeParkingFilters.length)
                        : `Parking (${activeParkingFilters.length} to 4)`}
                    </label>
                    <div className="filter-chips">
                      {parkingLabels.map((label, index) => (
                        <div
                          key={index}
                          className={`filter-chip ${activeParkingFilters.includes(label) ? 'active' : ''}`}
                          onClick={() => handleParkingClick(label)}
                        >
                          {label}
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr />
                  <div className="filter-block3">
                    <label className="filter-label1"> {translated.vehiclep || 'Vehicle & Plugs'}</label>
                    <label className="filter-label3">{translated.vehiclep1 || 'Your vehicle is used to determine compatible charging stations'}</label>
                    <label className="filter-label1" onClick={togglePlugDropdown} style={{ cursor: 'pointer' }}>
                      {translated.plugs
                        ? translated.plugs.replace('{value}', activePlugFilters.length)
                        : `Plugs (${activePlugFilters.length} of 13)`}
                      <span className="dropdown-icon">&#9662;</span>
                    </label>

                    {isPlugOpen && (
                      <div className="filter-box1">
                        {filterPlugLabels.map((label, index) => (
                          <div
                            key={index}
                            className={`filter-item1 ${activePlugFilters.includes(label) ? 'active' : ''}`}
                            onClick={() => handlePlugFilterClick(label)}
                          >
                            {label === 'CCS2' && (
                              <div className="label-container">
                                <img
                                  src={"https://assets.plugshare.com/outlets/images/ccs-type2.svg"}
                                  alt="CCS2"
                                  className="type-icon"
                                />
                                <span className="label-text">CCS2</span>
                              </div>
                            )}
                            {label === 'CCS1' && (
                              <div className="label-container">
                                <img
                                  src={"https://assets.plugshare.com/outlets/images/ccs.svg"}
                                  alt="CCS1"
                                  className="type-icon"
                                />
                                <span className="label-text">CCS1</span>
                              </div>
                            )}
                            {label === 'CHAdeMO' && (
                              <div className="label-container">
                                <img
                                  src={"https://assets.plugshare.com/outlets/images/chademo.svg"}
                                  alt="CHAdeMO"
                                  className="type-icon"
                                />
                                <span className="label-text">CHAdeMO</span>
                              </div>
                            )}
                            {label === 'J-1772' && (
                              <div className="label-container">
                                <img
                                  src={"https://assets.plugshare.com/outlets/images/j1772.svg"}
                                  alt="J-1772"
                                  className="type-icon"
                                />
                                <span className="label-text">J-1772</span>
                              </div>
                            )}
                            {label === 'Type2' && (
                              <div className="label-container">
                                <img
                                  src={"https://assets.plugshare.com/outlets/images/type2.svg"}
                                  alt="Type 2"
                                  className="type-icon"
                                />
                                <span className="label-text">Type 2</span>
                              </div>
                            )}
                            {label === 'Type3' && (
                              <div className="label-container">
                                <img
                                  src={"https://assets.plugshare.com/outlets/images/type3.svg"}
                                  alt="Type 3"
                                  className="type-icon"
                                />
                                <span className="label-text">Type 3</span>
                              </div>
                            )}
                            {label === 'Three' && (
                              <div className="label-container">
                                <img
                                  src={"https://assets.plugshare.com/outlets/images/three-phase.svg"}
                                  alt="Three Phase"
                                  className="type-icon"
                                />
                                <span className="label-text">Three Phase</span>
                              </div>
                            )}
                            {label === 'Caravan' && (
                              <div className="label-container">
                                <img
                                  src={"https://assets.plugshare.com/outlets/images/commando.svg"}
                                  alt="Caravan Main Socket"
                                  className="type-icon1"
                                />
                                <span className="label-text1">Caravan Main Socket</span>
                              </div>
                            )}
                            {label === 'GB/T' && (
                              <div className="label-container">
                                <img
                                  src={"https://assets.plugshare.com/outlets/images/type2.svg"}
                                  alt="GB/T"
                                  className="type-icon"
                                />
                                <span className="label-text">GB/T</span>
                              </div>
                            )}
                            {label === 'GB/T fast' && (
                              <div className="label-container">
                                <img
                                  src={"https://assets.plugshare.com/outlets/images/gbt2.svg"}
                                  alt="GB/T Fast"
                                  className="type-icon"
                                />
                                <span className="label-text">GB/T Fast</span>
                              </div>
                            )}
                            {label === 'Wall' && (
                              <div className="label-container">
                                <img
                                  src={"https://assets.plugshare.com/outlets/images/wall.svg"}
                                  alt="Wall"
                                  className="type-icon"
                                />
                                <span className="label-text">Wall</span>
                              </div>
                            )}
                            {label === 'Wall2' && (
                              <div className="label-container">
                                <img
                                  src={"https://assets.plugshare.com/outlets/images/wall-bs1363.svg"}
                                  alt="Wall (BS1363)"
                                  className="type-icon"
                                />
                                <span className="label-text1">Wall (BS1363)</span>
                              </div>
                            )}
                            {label === 'Wall3' && (
                              <div className="label-container">
                                <img
                                  src={"https://assets.plugshare.com/outlets/images/wall-eu.svg"}
                                  alt="Wall (Euro)"
                                  className="type-icon"
                                />
                                <span className="label-text">Wall (Euro)</span>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <hr />

                  <div className="re-button-container">
                    <label className="re-button" onClick={handleReset}>
                      {translated.reset || 'Reset'}
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="dropdown">
            <button onClick={() => toggleDropdown('plugHistory')}>
              <i className="fa fa-bolt" aria-hidden="true"></i>
              {translated.plug_history || 'Pay with PlugShare History'}
              <span className="dropdown-icon">&#9662;</span>
            </button>
            {dropdownOpen.plugHistory && (
              <div className="dropdown-content">
                <div className='filter-block4'>
                  <label><i>{translated.history1 || 'Get started with pay with PlugShare'}</i></label>
                </div>

              </div>
            )}
          </div>


          <div className="dropdown">
            <button onClick={() => toggleDropdown('addStations')} >
              <i className="fa fa-gas-pump" aria-hidden="true"></i>
              {translated.add_station || 'Add Stations'}
              <span className="dropdown-icon">&#9662;</span>
            </button>
            {dropdownOpen.addStations && (
              <div className="dropdown-content">
                <div className='filter-block4' onClick={openPublic} >
                  <label >{translated.addS1 || 'Add Public Location'}</label>
                </div>
                <div className='filter-block4' onClick={openHome}>
                  <label>{translated.addS2 || 'Share Home Charger'}</label>
                </div>
              </div>
            )}

<AddPublicStation isOpen={isPublicStationOpen} closePublic={closePublic} >
        <p>Here you can enter details for the new public location.</p>
      </AddPublicStation>

      <AddHomeStation isOpen={isHomeStationOpen} closeHome={closeHome} >
        <p>Here you can enter details for the new public location.</p>
      </AddHomeStation>
          </div>
         
    

          <div className="dropdown">
            <button onClick={() => toggleTripDropdown('tripPlanner')}>
              <i className="fa fa-car" aria-hidden="true"></i>
              {translated.trip || 'Trip Planner'}
              <span className="dropdown-icon">&#9662;</span>
            </button>
            {tripDropdownOpen.tripPlanner && (
              <div className="dropdown-content">
                <div className='filter-block4' onClick={toggleAdditionalContainer}>
                  <label><b>{translated.trip1 || 'Plan a New Trip'}</b></label>
                </div>
              </div>
            )}
          </div>

          {isTripPlannerOpen && <TripPlanner />}

          <div className="dropdown">
            <button onClick={() => toggleDropdown('recentActivity')}>
              <i className="fa fa-history" aria-hidden="true"></i>
              {translated.recent || 'Recent Activity'}
            </button>
          </div>

          <div className="dropdown">
            <button onClick={() => toggleDropdown('settings')}>
              <i className="fa fa-cog" aria-hidden="true"></i>
              {translated.setting || 'Settings'}
            </button>
          </div>

          <div className="dropdown">
            <button onClick={() => toggleDropdown('help')}>
              <i className="fa fa-question-circle" aria-hidden="true"></i>
              {translated.help || 'Help'}
            </button>
          </div>

          <div className="dropdown">
            <button onClick={() => toggleDropdown('submitFeedback')}>
              <i className="fa fa-comment-dots" aria-hidden="true"></i>
              {translated.feedback || 'Submit Feedback'}
            </button>
          </div>

          <div className="dropdown">
            <button onClick={() => toggleDropdown('plugShareStore')}>
              <i className="fa fa-shopping-cart" aria-hidden="true"></i>
              {translated.store || 'PlugShare Store'}
            </button>
          </div>

          <div className="dropdown">
            <button onClick={() => toggleDropdown('getTheApp')}>
              <i className="fa fa-download" aria-hidden="true"></i>
              {translated.app || 'Get the App'}
              <span className="dropdown-icon">&#9662;</span>
            </button>
            {dropdownOpen.getTheApp && (
              <div className="dropdown-content">
                <p>Get the App content goes here.</p>
              </div>
            )}
          </div>
        </div>
      )}
       <AddStation isOpen={isModalOpen} onClose={closeModal} />
       <MapComponent currentLocation={currentLocation} />
    </>
  );
};

export default Sidebar;
