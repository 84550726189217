import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './StationPopup.css';

const StationPopup = ({ isOpen, station, onClose }) => {
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [imageUrl, setImageUrl] = useState(station?.imageUrl || '');
    const [imageFile, setImageFile] = useState(null);
    const [caption, setCaption] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isImageUploadPopupOpen, setIsImageUploadPopupOpen] = useState(false);
    const [uploadedImages, setUploadedImages] = useState([]);

    const toggleBookmark = () => {
        setIsBookmarked(!isBookmarked);
    };

    const bookmarkClass = isBookmarked ? 'bookmarked' : '';


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageUrl(reader.result); // Set the base64 string as the new image URL
                setImageFile(file); // Save the file object to send to the server
            };
            reader.readAsDataURL(file); // Convert the file to base64
        }
    };

    useEffect(() => {
        const fetchUploadedImages = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_GET_IMAGES}${station.id}`
                );
                //console.log(response.data); // Inspect the structure here
                // Assuming the images are under the `data` key
                if (response.data && Array.isArray(response.data.data)) {
                    setUploadedImages(response.data.data); // Set images correctly
                } else {
                    setUploadedImages([]); // In case the response is not as expected
                }
            } catch (error) {
                console.error("Error fetching images:", error);
            }
        };
    
        if (isOpen && station) {
            fetchUploadedImages();
        }
    }, [isOpen, station]);
    

    const handleUploadImage = async () => {
        if (!imageFile || !caption) {
            setErrorMessage('Please select an image and provide a caption.');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', imageFile);
            formData.append('caption', caption);

            // Assuming your API URL is stored in an environment variable
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_ADD_IMAGES}${station.id}`,
                formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }
            );

            if (response.data.success) {
                setSuccessMessage(response.data.message);
                setErrorMessage('');
                setCaption('');
                setImageFile(null);
                
                setTimeout(() => {
                    setIsImageUploadPopupOpen(false); 
                    setSuccessMessage(''); 
                }, 2000);
            } else {
                setErrorMessage(response.data.message);
                setSuccessMessage('');
            }
        } catch (error) {
            setErrorMessage('An error occurred while uploading the image.');
            setSuccessMessage('');
        }
    };

    const openImageUploadPopup = () => {
        setIsImageUploadPopupOpen(true);
    };

    const closeImageUploadPopup = () => {
        setIsImageUploadPopupOpen(false);
        setCaption('');
        setImageFile(null);
        setErrorMessage('');
        setSuccessMessage('');
    };

    if (!isOpen) return null;

    return (
        <div className="popup">
            {/* Image and Title Section */}
            <div className="popup-header-image">
                <img
                    src={station?.imageUrl || 'https://photos.plugshare.com/photos/735349.jpg'}
                    alt="Station"
                    className="header-image"
                />
                <div className="header-details">
                    <h3>{station?.name || 'Station Name'}</h3>
                    <button className="close-btn" onClick={onClose}>✕</button>
                </div>
            </div>
            {/* Main Station Info */}
            <div className="station-details">
                <div className="rating-section">
                    <div className="rating">{station?.rating || 10}</div>
                    <p className='desc-state'>{station?.desc || ''}</p>
                </div>
                <button className="checkin-btn">Check In</button>
            </div>

            <div className="info-item">
                <div className="stationbutton">
                <button className={`action-btn ${bookmarkClass}`} onClick={toggleBookmark}>
                        <i className="fa fa-bookmark"></i>
                        <span>Bookmark</span>
                    </button>
                    <button className="action-btn" onClick={openImageUploadPopup}>
                        <i className="fa fa-camera"></i>
                        <span><br></br>Add Photo</span>
                    </button>
                    <button className="action-btn">
                        <i className="fa fa-directions"></i>
                        <span>Directions</span>
                    </button>
                    <button className="action-btn">
                        <i className="fa fa-flag"></i>
                        <span>Report</span>
                    </button>
                </div>
            </div>

            {/* Info and Actions Section */}
            <div className="info-actions">
                <div className="info">
                    <div className="info-item">
                        <span className="fa fa-map-marker" aria-hidden="true"></span>
                        <p>{station?.address || '3130 Colima Rd, Hacienda Heights, CA 91745, USA'}</p>
                    </div>
                    <div className="info-item">
                        <span className="fa fa-phone" aria-hidden="true"></span>
                        <p>{station?.phone || '+1 877-494-3833'}</p>
                    </div>
                    <div className="info-item">
                        <span className="fa fa-credit-card" aria-hidden="true"></span>
                        <p>{station?.paymentRequired === 1 ? 'Payment Required' : 'Free'}</p>
                    </div>
                    <div className="info-item">
                        <span className="fas fa-clock" aria-hidden="true"></span>
                        <p
                            style={station?.openStatus === 1
                                ? { color: 'green', fontWeight: 'bold' }
                                : { color: 'red', fontWeight: 'bold' }}>
                                {station?.openStatus === 1 ? 'Available' : 'Unavailable'}
                        </p>
                    </div>

                    <div className="info-item-parking">
                        <span class="fas fa-parking" aria-hidden="true"></span>
                        <p className='parking'>{station?.parkingAttributes && station.parkingAttributes.length > 0 ? 'Parking : Free' : 'Parking : Unavailable'}</p>
                        {station.parkingAttributes.map((attribute) => (
                            <p key={attribute.id}>{attribute.name}</p>
                        ))}
                    </div>
                    <div className="info-item">
                        <span className="fa-solid fa-hotel" aria-hidden="true"></span>
                        <p>
                            {station.amentities.map((attribute) => attribute.amentityName).join(", ")}
                        </p>
                    </div>

                    <div className="info-item-plug">
                        {station.selectedPlugs.map((plug) => (
                            <div key={plug.id} className="plug-container">
                                <h4>{plug.connectorTypeName}</h4>
                                <p>Status: {plug.status === 1 ? "Available" : "Unavailable"}</p> {/* Assuming 1 means available */}
                                <p>Description: {plug.desc ? plug.desc : "No description available"}</p>
                            </div>
                        ))}

                    </div>
                    <div className="uploaded-images">
    <h4>Uploaded Images</h4>
    <div className="images-grid">
        {uploadedImages && uploadedImages.length > 0 ? (
            uploadedImages.map((image, index) => (
                <div key={index} className="uploaded-image">
                    <img src={image.path} alt={`Uploaded ${index + 1}`} />
                    <p>{image.caption}</p>
                </div>
            ))
        ) : (
            <p>No images uploaded yet.</p>
        )}
    </div>
</div>




                </div>



            </div>

            {isImageUploadPopupOpen && (
                <div className="image-upload-popup">
                    <div className="img-popup-content">
                        <div className='img-header'>
                        <h3>Add Photo</h3>
                        <button className="img-close-btn" onClick={onClose}>✕</button>
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className='custom-file-input'
                        />
                        <input
                            type="text"
                            placeholder="Enter Caption"
                            value={caption}
                            onChange={(e) => setCaption(e.target.value)}
                        />
                        {errorMessage && <p className="error">{errorMessage}</p>}
                        {successMessage && <p className="success">{successMessage}</p>}
                        <button className='img-upload' onClick={handleUploadImage}>Upload</button>
                        <button className='img-cancel'onClick={closeImageUploadPopup}>Cancel</button>
                    </div>
                </div>
            )}

        </div>
    );
};

export default StationPopup;
