/* // src/Components/LoginPopup.jsx
import React, { useState } from 'react';
import axios from 'axios';
import TranslationObj from '../../Data/TranslationObj.jsx';
import { useLanguage } from '../../Context/LanguageContext.jsx';
import { GoogleLogin } from '@react-oauth/google';
import './Reg.css';

const RegisterPopup = ({ isOpen, onClose , openLoginPopup , openVerificationPopup, handleEmailUpdate }) => {
  const { language } = useLanguage();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  
  if (!TranslationObj[language]) {
    console.error(`Language "${language}" is not defined in TranslationObj.`);
    return null;
  }

  const translated = TranslationObj[language];

  if (!isOpen) return null;
  const isValidEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  const isValidPassword = (password) => password.length >= 6;

  const handleRegister = async (e) => {
    
    e.preventDefault();
  
    if (!isValidEmail(email)) {
      setErrMsg('Invalid email address.');
      return;
    }
  
    if (!isValidPassword(password)) {
      setErrMsg('Password must be at least 6 characters long.');
      return;
    }
  
    const registerData = { username: email, password: password };
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_REGISTER}`, 
        registerData
      );
      const data = response.data;
      if (data.success) {
        sessionStorage.setItem('userName', data.data.username);
        localStorage.setItem('userEmail', data.data.username); 
        sessionStorage.setItem('token', data.data.token || ''); 
        sessionStorage.setItem('refresh-Token', data.data.refreshToken || ''); 
        setSuccessMsg('Successfully Registered');
        handleEmailUpdate(email);
        setErrMsg(''); 
        setTimeout(() => {
          onClose();
          openVerificationPopup(email);
        }, 2000);
      } else {
        setSuccessMsg('');
        setErrMsg(data.message || 'Registration failed');
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setErrMsg(err.response.data.message);
      } else {
        setErrMsg('No Server Response!');
      }
    }
  };

  const handleGoogleLogin = async (response) => {
    try {
      const googleToken = response.credential;
  
      if (!googleToken) {
        setErrMsg('Google login failed. No token received.');
        return;
      }
  
      // Now send this token to your backend for validation and user authentication
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/google-login`, // Adjust the URL as needed
        { token: googleToken }
      );
  
      // Check if the backend response was successful
      if (result.data.success) {
        const { username, token, refreshToken } = result.data.data;
  
        // Store user info in sessionStorage and localStorage for future requests
        sessionStorage.setItem('userName', username);
        sessionStorage.setItem('token', token || '');
        sessionStorage.setItem('refreshToken', refreshToken || '');
  
        setSuccessMsg('Successfully Logged in with Google');
        onClose(); // Close the popup after login
      } else {
        setErrMsg(result.data.message || 'Google login failed');
      }
    } catch (err) {
      console.error('Google login error:', err);
      setErrMsg('Google login failed. Please try again later.');
    }
  };
  

  const handleClose = () => {
    setEmail('');
    setPassword('');
    setErrMsg('');
    setSuccessMsg('');
    onClose();  // Ensure the parent component handles closing the popup
  };

  
  return (
    <div className="popup-overlay-login">
      <div className="popup-content-login">


        <div className="popup-inner-container">
          <div className="left-container-login">
            <h2>{translated.I1 || 'Have you tried the PlugShare Trip Planner?'}</h2>
            <p>{translated.p1 || 'Plan your next trip with our easy to use tool:'}</p>
            <ul>
              <li>
                <span>{translated.I2 || '✓ Focus your search:'}</span><br />
                <span className="sub-text">{translated.d1 || 'Only show locations along your route'}</span>
              </li>
              <li>
                <span>{translated.I3 || '✓ Simple yet flexible:'}</span><br />
                <span className="sub-text">{translated.d2 || 'Filter to be nearby hotels, restaurants, etc.'}</span>
              </li>
              <li>
                <span>{translated.I4 || '✓ Elevation Profiles:'}</span><br />
                <span className="sub-text">{translated.d3 || 'Helps you plan accordingly'}</span>
              </li>
              <li>
                <span>{translated.I5 || '✓ Save and load trips:'}</span><br />
                <span className="sub-text">{translated.d4 || 'Works across devices - its mobile friendly!'}</span>
              </li>
            </ul>

            <p>{translated.p3 || 'Login or Register Now for Instant Access'}</p>
          </div>

          <div className="right-container-login">
            <button className="close-btn-login" onClick={handleClose}>X</button>
            <h2>{translated.regpg || 'Register'}</h2>

            <form className='form-login' onSubmit={handleRegister}>
              <input
                className='input-login'
                type="text"
                placeholder={translated.un || 'Email'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                className='input-login'
                type="password"
                placeholder={translated.pw || 'Password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button className='button-login' type="submit">
                {translated.btnreg || 'Create Account'}
              </button>

              {errMsg && <p className="error-message">{errMsg}</p>}
            {successMsg && <p className="success-message">{successMsg}</p>}

            <button className="google-login-btn">
            <div className="google-icon-container">
                <img
                  src="https://assets.plugshare.com/assets/google-icon.png" 
                  alt="Google Logo" 
                  className="google-logo" 
                />
                </div>
                {translated.google || 'Continue with Google'}
              </button>

              <div className="login-footer">
                <span>
                  {translated.rg || 'Already have an account?'} 
                  <a href="#" onClick={openLoginPopup}>{translated.loginpg || 'Login'}</a>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPopup;
 */

import React, { useState } from 'react';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import TranslationObj from '../../Data/TranslationObj.jsx';
import { useLanguage } from '../../Context/LanguageContext.jsx';
import { useUser } from '../../Context/UserContext.jsx';
import './Reg.css';

const RegisterPopup = ({ isOpen, onClose , openLoginPopup , openVerificationPopup, handleEmailUpdate }) => {
  const { language } = useLanguage();
  const { setUserId } = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  if (!TranslationObj[language]) {
    console.error(`Language "${language}" is not defined in TranslationObj.`);
    return null;
  }

  const translated = TranslationObj[language];

  if (!isOpen) return null;
  const isValidEmail = (email) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  const isValidPassword = (password) => password.length >= 6;

  const handleRegister = async (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setErrMsg('Invalid email address.');
      return;
    }

    if (!isValidPassword(password)) {
      setErrMsg('Password must be at least 6 characters long.');
      return;
    }

    const registerData = { username: email, password: password };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_REGISTER}`,
        registerData
        
      );
      const data = response.data;
      if (data.success) {
        setUserId(data.data.userId);
        sessionStorage.setItem('userName', data.data.username);
        localStorage.setItem('userEmail', data.data.username);
        sessionStorage.setItem('token', data.data.token || '');
        sessionStorage.setItem('refresh-Token', data.data.refreshToken || '');
        setSuccessMsg('Successfully Registered');
        handleEmailUpdate(email);
        setErrMsg('');
        setTimeout(() => {
          onClose();
          openVerificationPopup(email);
        }, 2000);
      } else {
        setSuccessMsg('');
        setErrMsg(data.message || 'Registration failed');
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setErrMsg(err.response.data.message);
      } else {
        setErrMsg('No Server Response!');
      }
    }
  };

  const handleClose = () => {
    setEmail('');
    setPassword('');
    setErrMsg('');
    setSuccessMsg('');
    onClose();  // Ensure the parent component handles closing the popup
  };


  const handleGoogleLogin = async (response) => {
    try {
        const googleToken = response.credential;
        if (!googleToken) {
            setErrMsg('Google register failed. No token received.');
            return;
        }

        const result = await axios.post(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_GOOGLE}`,
            { token: googleToken }
        );

        
        const data = result.data;

        if (data.success) {
          const token = data.data.token; // Extract token from the API response
          localStorage.setItem('token', token);
          setUserId(data.data.userId);
            sessionStorage.setItem('userName', data.data.username);
            localStorage.setItem('userName', data.data.username);
            sessionStorage.setItem('token', data.data.token || '');
            sessionStorage.setItem('refreshToken', data.data.refreshToken || '');

            setSuccessMsg('Successfully Logged in with Google');
            handleEmailUpdate(data.data.username);
            setErrMsg('');

            // Close popup after a delay
            setTimeout(() => {
                onClose();
                setSuccessMsg('')
            }, 2000);  // You can change this timeout duration if needed
        } else {
            setSuccessMsg('');
            setErrMsg(data.message || 'Google register failed');
        }
    } catch (err) {
        console.error('Google register error:', err);
        setErrMsg('Google register failed. Please try again later.');
    }
};


  return (
    <div className="popup-overlay-login">
      <div className="popup-content-login">
        <div className="popup-inner-container">
          <div className="left-container-login">
            <h2>{translated.I1 || 'Have you tried the PlugShare Trip Planner?'}</h2>
            <p>{translated.p1 || 'Plan your next trip with our easy to use tool:'}</p>
            <ul>
              <li>
                <span>{translated.I2 || '✓ Focus your search:'}</span><br />
                <span className="sub-text">{translated.d1 || 'Only show locations along your route'}</span>
              </li>
              <li>
                <span>{translated.I3 || '✓ Simple yet flexible:'}</span><br />
                <span className="sub-text">{translated.d2 || 'Filter to be nearby hotels, restaurants, etc.'}</span>
              </li>
              <li>
                <span>{translated.I4 || '✓ Elevation Profiles:'}</span><br />
                <span className="sub-text">{translated.d3 || 'Helps you plan accordingly'}</span>
              </li>
              <li>
                <span>{translated.I5 || '✓ Save and load trips:'}</span><br />
                <span className="sub-text">{translated.d4 || 'Works across devices - its mobile friendly!'}</span>
              </li>
            </ul>
            <p>{translated.p3 || 'Login or Register Now for Instant Access'}</p>
          </div>

          <div className="right-container-login">
            <button className="close-btn-login" onClick={handleClose}>X</button>
            <h2>{translated.regpg || 'Register'}</h2>

            <form className='form-login' onSubmit={handleRegister}>
              <input
                className='input-login'
                type="text"
                placeholder={translated.un || 'Email'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                className='input-login'
                type="password"
                placeholder={translated.pw || 'Password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <button className='button-login' type="submit">
                {translated.btnreg || 'Create Account'}
              </button>

              {errMsg && <p className="error-message">{errMsg}</p>}
              {successMsg && <p className="success-message">{successMsg}</p>}

              <GoogleLogin
                onSuccess={handleGoogleLogin}
                onError={() => setErrMsg('Google register failed')}
                render={(renderProps) => (
                  <button
                    className="google-login-btn"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <div className="google-icon-container">
                      <img
                        src="https://assets.plugshare.com/assets/google-icon.png"
                        alt="Google Logo"
                        className="google-logo"
                      />
                    </div>
                    {translated.google || 'Continue with Google'}
                  </button>
                )}
              />
              <div className="login-footer">
                <span>
                  {translated.rg || 'Already have an account?'} 
                  <a href="#" onClick={openLoginPopup}>{translated.loginpg || 'Login'}</a>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPopup;
