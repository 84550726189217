import React, { useState } from 'react';
import MapComponent from '../Map/MapComponent';

import { useLocation } from '../../Context/LocationProvider'; // Import useLocation hook
import { useLanguage } from '../../Context/LanguageContext.jsx';
import TranslationObj from '../../Data/TranslationObj.jsx';
import './TripPlanner.css';

const TripPlanner = () => {
  const { language } = useLanguage();
  const {
    currentLocation,
    startingLocation,
    setStartingLocation,
    destination,
    setDestination
  } = useLocation(); // Access location context
  
  let destCoords;
  let startCoords;
  const [searchRadius, setSearchRadius] = useState(17.7);
  const [maxRange, setMaxRange] = useState(386.2);
  const [showAlongRoute, setShowAlongRoute] = useState(true);
  const [showRangeEstimate, setShowRangeEstimate] = useState(true);
  const [tripLocation, setTripLocation] = useState(null); // Trip location for map
  const [destinationCoords, setDestinationCoords] = useState(null); // For destination coordinates
  const [showMap, setShowMap] = useState(false); 
  const [isTripPlannerOpen, setIsTripPlannerOpen] = useState(true);

  const translated = TranslationObj[language];

  // Fetch current location using browser's geolocation API
  const fetchCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const location = { lat: latitude, lng: longitude };
          setTripLocation(location);
          setStartingLocation('My location');
          //console.log('Current location fetched:', location);
        },
        (error) => {
          console.error('Error fetching location:', error);
        }
      );
    } else {
      alert('Geolocation is not supported by your browser.');
    }
  };

  // Handle starting location input change
  const handleStartingLocationChange = (e) => {
    const value = e.target.value;
    setStartingLocation(value);
    if (value.toLowerCase() === 'my location') {
      fetchCurrentLocation();
    }
  };

  // Geocode location to get coordinates (use your own API key here)
  const geocodeLocation = async (location) => {
    const apiKey = 'AIzaSyAeXVjhs4TY9yS03EicdFTK02mpWLjEmtk';
    let url;

    if (location === 'My location' && tripLocation) {
      // Use current location coordinates
      url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${tripLocation.lat},${tripLocation.lng}&key=${apiKey}`;
    } else {
      // Use address
      url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(location)}&key=${apiKey}`;
    }

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch geocode data');
      }

      const data = await response.json();
      //console.log('Geocode API response:', data);

      if (data.status === 'OK' && data.results && data.results[0]) {
        return data.results[0].geometry.location; // { lat, lng }
      } else {
        console.warn('Geocoding error:', data.status);
        alert('Could not fetch coordinates for the location.');
        return null;
      }
    } catch (error) {
      console.error('Error in geocodeLocation:', error);
      alert('An error occurred while fetching geocode data.');
      return null;
    }
  };

  // Handle the search button click
  const handleSearch = async () => {
    //console.log('Searching for route from:', startingLocation, 'to:', destination);
  
     startCoords = await geocodeLocation(startingLocation);
    destCoords = await geocodeLocation(destination);
  
    if (startCoords && destCoords) {
      //console.log('Coordinates fetched:', { startCoords, destCoords });
  
      // Set the trip location as a formatted string
      setTripLocation(`My Location: ${startCoords.lat}, ${startCoords.lng}`);
      
      // Format starting location to display coordinates as a string
      setStartingLocation(startCoords);
  
      setDestinationCoords(destCoords);
      setShowMap(true); // Show map with the route
    } else {
      console.error('Invalid coordinates:', { startCoords, destCoords });
      alert('Unable to find a valid route. Please check the locations entered.');
    }
  };
  
  // Clear search input
  const clearSearch = () => {
    setStartingLocation('');
    setDestination('');
    setShowMap(false);
    setTripLocation(null);
    setDestinationCoords(null);
    startCoords = '';
  };
  
  const handleClose = () => {
    setIsTripPlannerOpen(false); // Set trip planner to closed state
  };

  if (!isTripPlannerOpen) return null;

  return (
    <div className="trip-additional-container">
      <div className="trip-header">
        <h3>{translated.trip1 || 'Plan a New Trip'}</h3>
        <button className="trip-close-button" onClick={handleClose}>&times;</button>
      </div>

    <div className="trip-location-input">
        <label>{translated.starting || 'Start'}</label>
        <input
          type="text"
          placeholder="Enter Starting Location"
          value={startCoords}
          onChange={handleStartingLocationChange}
        />
        
      </div>

      <div className="trip-location-input">
        <label>{translated.destination || 'Destination'}</label>
        <input
          type="text"
          placeholder="Enter Destination"
          value={destination}
          onChange={(e) => setDestination(e.target.value)}
        />
        
      </div>

      <div className="trip-options-section">
        <div className="trip-option-toggle">
          <label>
            <input
              type="checkbox"
              checked={showAlongRoute}
              onChange={() => setShowAlongRoute(!showAlongRoute)}
            />
            {translated.alongRoute || 'Show Along Route Only'}
          </label>
        </div>

        <div className="trip-slider-container">
          <label>{translated.radius || 'Search Radius'}: {searchRadius.toFixed(1)} kilometers</label>
          <input
            type="range"
            min="0"
            max="50"
            step="0.1"
            value={searchRadius}
            onChange={(e) => setSearchRadius(parseFloat(e.target.value))}
          />
        </div>

        {showRangeEstimate && (
          <div className="trip-slider-container">
            <label>{translated.maxRange || 'Estimated Max Range'}: {maxRange.toFixed(1)} kilometers</label>
            <input
              type="range"
              min="0"
              max="500"
              step="0.1"
              value={maxRange}
              onChange={(e) => setMaxRange(parseFloat(e.target.value))}
            />
          </div>
        )}
      </div>

      <div className="trip-search-button-container">
        <button onClick={handleSearch} className="trip-search-button">
          {translated.search || 'Search'}
        </button>
        <button onClick={clearSearch} className="trip-clear-button">
          Clear
        </button>
      </div>

     
    </div>
  );
};

export default TripPlanner;
